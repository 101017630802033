import React, {useContext, useEffect, useMemo, useState} from 'react';
import ConsultantService from "../services/ConsultantService";
import cl from './styles/ClientList.module.css'
import { useFetching } from "../hooks/useFetching";
import Loader from "../UI/loader/Loader";
import MyInput from "../UI/input/MyInput";
import MyButton from "../UI/button/MyButton";
import {Link, useNavigate} from "react-router-dom";
import {Context} from "../index";
import cfg from "../cfg";
import AdminService from "../services/AdminService";

const ClientsList = () => {
    const {store} = useContext(Context)
    const [clients, setClients] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [fetchClients, isClientsLoading, error] = useFetching(async () => {
        if (store.userRoleID === cfg.adminID ||
            (store.userRoleID === cfg.consultantID && store.typeOfActivity === 'group_leader')) {
            const response = await AdminService.getClients()
            setClients(response)
        }
        else if (store.userRoleID === cfg.consultantID) {
            const response = await ConsultantService.getClients(store.typeOfActivity, store.userID)
            setClients(response)
        }
    })
    const navigate = useNavigate()

    useEffect(() => {
        fetchClients()
    }, []);


    const filteredClients = useMemo(() => {
        return clients.filter(user => (
            `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
            )))
    }, [searchQuery, clients])


        return (
            <div className={cl.clients}>
                <div className={cl.search_line}>
                    <MyInput
                        placeholder='Поиск клиентов...'
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value)
                        }}/>
                </div>
                {isClientsLoading
                    ? <div><Loader/></div>
                    : <div>
                        {filteredClients.length
                            ? filteredClients.map((row) => (
                                <div
                                    key={row.id}
                                >
                                    <Link
                                        className={cl.client_row}
                                        to={`/cons/client_profile/${row.id}`}
                                    >{row.surname} {row.name}</Link>
                                </div>
                            ))
                            : <h4>Клиенты не найдены</h4>
                        }
                    </div>
                }
            </div>
        );
};

export default ClientsList;