import React, {useState} from 'react';
import cl from './styles/Questionaire.module.css'
import Scales from "../components/Scales";

const Questionnaire = () => {
    const [typeScale, setTypeScale] = useState('')
    const [selectionIsDisable, setSelectionIsDisable] = useState(true)
    const typesData = [
        {value: 'depression', legend: 'шкала депрессии Бека'},
        {value: 'anxiety', legend: 'шкала тревоги Бека'},
        {value: 'fond', legend: 'анкета актуального состояния'},
    ]

    const agreementData = [
        {value: false, legend: 'Подтверждаю', checked: false},
        {value: true, legend: 'Не подтверждаю', checked: true}
    ]

    const anxietyAnswers = [
        {desc: 'Симптом совсем не беспокоил', value: 0},
        {desc: 'Слегка, не слишком меня беспокоил', value: 1},
        {desc: 'Умеренно. Было неприятно, но можно переносить', value: 2},
        {desc: 'Очень сильно. Я с трудом могу это выносить', value: 3}
    ]
    const depressionData = [
        {
            legend: 'Вы чувствуете себя расстроенным?',
            number: 1,
            data: [
                {desc: 'Я не чувствую себя расстроенным, печальным', value: 0},
                {desc: 'Я расстроен', value: 1},
                {desc: 'Я все время расстроен и не могу освободиться от этого чувства', value: 2},
                {desc: 'Я так печален и несчастлив, что я не могу этого вынести', value: 3}
            ]
        },
        {
            legend: 'Вы тревожитесь о своем будущем?',
            number: 2,
            data: [
                {desc: 'Я не особенно тревожусь о своем будущем', value: 0},
                {desc: 'Я чувствую, что озадачен будущим', value: 1},
                {desc: 'Я чувствую, что мне не на что рассчитывать в будущем', value: 2},
                {desc: 'Мое будущее безнадежно и ничто не может измениться к лучшему', value: 3}
            ]
        },
        {
            legend: 'Вас преследуют неудачи?',
            number: 3,
            data: [
                {desc: 'Я не чувствую себя неудачником', value: 0},
                {desc: 'Я потерпел больше неудач в жизни, чем другие люди', value: 1},
                {desc: 'Когда я оглядываюсь на свою жизнь, все что я вижу – это череда неудач', value: 2},
                {desc: 'Я чувствую, что как личность я - полный неудачник', value: 3}
            ]
        },
        {
            legend: 'Вы получаете удовлетворение от жизни?',
            number: 4,
            data: [
                {desc: 'Я удовлетворен своей жизнью так же, как раньше', value: 0},
                {desc: 'Я не получаю такого же удовольствия, как раньше', value: 1},
                {desc: 'Я больше не получаю настоящего удовлетворения от чего бы то ни было', value: 2},
                {desc: 'Я полностью не удовлетворен жизнью, и все мне надоело', value: 3}
            ]
        },
        {
            legend: 'Вы чувствуете себя виноватым?',
            number: 5,
            data: [
                {desc: 'Я не чувствую себя виноватым в чем-либо', value: 0},
                {desc: 'Я чувствую себя виноватым значительную часть времени', value: 1},
                {desc: 'Я чувствую себя виноватым большую часть времени', value: 2},
                {desc: 'Я чувствую себя виноватым все время', value: 3}
            ]
        },
        {
            legend: 'Вы чувствуете, что Вас можно за что-либо наказать?',
            number: 6,
            data: [
                {desc: 'Я не чувствую, что могу быть наказанным за что-либо', value: 0},
                {desc: 'Я чувствую, что могу быть наказан за что-либо', value: 1},
                {desc: 'Я ожидаю, что буду наказан', value: 2},
                {desc: 'Я чувствую себя уже наказанным', value: 3}
            ]
        },
        {
            legend: 'Вы разочарованы в себе?',
            number: 7,
            data: [
                {desc: 'Я не испытываю разочарования в себе', value: 0},
                {desc: 'Я разочарован в себе', value: 1},
                {desc: 'Я испытываю отвращение к себе', value: 2},
                {desc: 'Я ненавижу себя', value: 3}
            ]
        },
        {
            legend: 'Вы критично относитесь к себе?',
            number: 8,
            data: [
                {desc: 'Я ни в чем не хуже других', value: 0},
                {desc: 'Я критикую себя за свои слабости и ошибки', value: 1},
                {desc: 'Я все время виню себя за свои ошибки', value: 2},
                {desc: 'Я обвиняю себя во всем плохом, что происходит', value: 3}
            ]
        },
        {
            legend: 'Вы думаете о самоубийстве?',
            number: 9,
            data: [
                {desc: 'Я никогда не думал покончить с собой', value: 0},
                {desc: 'У меня есть мысли о том, чтобы покончить с собой, но я этого не сделаю', value: 1},
                {desc: 'Я хотел бы покончить с собой', value: 2},
                {desc: 'Я бы убил себя, если бы представился случай', value: 3}
            ]
        },
        {
            legend: 'Вы стали чаще плакать?',
            number: 10,
            data: [
                {desc: 'Я плачу не больше, чем обычно', value: 0},
                {desc: 'Сейчас я плачу чаще, чем обычно', value: 1},
                {desc: 'Сейчас я все время плачу', value: 2},
                {desc: 'Раньше я мог плакать, но сейчас не смогу, даже если мне захочется', value: 3}
            ]
        },
        {
            legend: 'Вы замечаете, что стали более раздражительны?',
            number: 11,
            data: [
                {desc: 'Я не более раздражителен, чем обычно', value: 0},
                {desc: 'Я раздражен чуть более обычного', value: 1},
                {desc: 'Теперь я постоянно чувствую, что раздражен', value: 2},
                {desc: 'Я всегда раздражен', value: 3}
            ]
        },
        {
            legend: 'Вы интересуетесь другими людьми?',
            number: 12,
            data: [
                {desc: 'Я не потерял интереса к другим людям', value: 0},
                {desc: 'Я меньше интересуюсь другими людьми', value: 1},
                {desc: 'Я практически потерял интерес к другим людям', value: 2},
                {desc: 'Я полностью потерял всякий интерес к другим людям', value: 3}
            ]
        },
        {
            legend: 'Вам стало сложнее принимать решения?',
            number: 13,
            data: [
                {desc: 'Я принимаю решения так же, как всегда', value: 0},
                {desc: 'Я откладываю принятие решений чаще, чем обычно', value: 1},
                {desc: 'Мне значительно сложнее принять решение, чем прежде', value: 2},
                {desc: 'Я больше не могу принимать какие-либо решения', value: 3}
            ]
        },
        {
            legend: 'Что Вы думаете о своей внешности?',
            number: 14,
            data: [
                {desc: 'Я не чувствую, что выгляжу хуже, чем обычно', value: 0},
                {desc: 'Я беспокоюсь, что выгляжу постаревшим или непривлекательным', value: 1},
                {
                    desc: 'Я знаю, что в моей внешности произошли существенные изменения, делающие меня непривлекательным',
                    value: 2
                },
                {desc: 'Я уверен, что выгляжу уродливо', value: 3}
            ]
        },
        {
            legend: 'Вы с легкостью выполняете какую-либо работу?',
            number: 15,
            data: [
                {desc: 'Я могу работать так же хорошо, как и раньше', value: 0},
                {desc: 'Мне необходимо приложить дополнительные усилия, чтобы начать что-то делать', value: 1},
                {desc: 'Я с большим трудом заставляю себя что-либо сделать', value: 2},
                {desc: 'Я вообще не могу выполнять никакую работу', value: 3}
            ]
        },
        {
            legend: 'Вы хорошо спите?',
            number: 16,
            data: [
                {desc: 'Я могу спать так же хорошо, как и раньше', value: 0},
                {desc: 'Сейчас я сплю хуже, чем обычно', value: 1},
                {desc: 'Я просыпаюсь на 1-2 часа раньше обычного и мне сложно снова заснуть', value: 2},
                {desc: 'Я просыпаюсь на несколько часов раньше обычного и больше не могу заснуть', value: 3}
            ]
        },
        {
            legend: 'Вы стали чаще уставать?',
            number: 17,
            data: [
                {desc: 'Я устаю не больше, чем обычно', value: 0},
                {desc: 'Я устаю быстрее обычного', value: 1},
                {desc: 'Я устаю практически от всего, что я делаю', value: 2},
                {desc: 'Я слишком устал, чтобы что-то делать', value: 3}
            ]
        },
        {
            legend: 'У Вас изменился аппетит?',
            number: 18,
            data: [
                {desc: 'Мой аппетит не хуже, чем обычно', value: 0},
                {desc: 'Мой аппетит не так хорош, как раньше', value: 1},
                {desc: 'Сейчас мой аппетит гораздо хуже', value: 2},
                {desc: 'У меня вообще нет никакого аппетита', value: 3}
            ]
        },
        {
            legend: 'У Вас изменился вес?',
            number: 19,
            data: [
                {desc: 'Мой вес если и изменился, то незначительно', value: 0},
                {desc: 'За последнее время я потерял в весе более 2 кг', value: 1},
                {desc: 'За последнее время я потерял в весе более 4 кг', value: 2},
                {desc: 'Я потерял более 7 кг', value: 3},
                {desc: 'Я намеренно стараюсь похудеть и ем меньше.', value: 0}
            ]
        },
        {
            legend: 'Вы беспокоитесь о своем здоровье?',
            number: 20,
            data: [
                {desc: 'Я беспокоюсь о своем здоровье не больше обычного', value: 0},
                {
                    desc: 'Меня беспокоят проблемы моего физического здоровья: болевые ощущения, расстройства желудка, запоры',
                    value: 1
                },
                {
                    desc: 'Я очень обеспокоен своим физическим состоянием, и мне трудно думать о чем-либо другом',
                    value: 2
                },
                {
                    desc: 'Я настолько обеспокоен своим физическим здоровьем, что просто не могу думать о чем-то еще',
                    value: 3
                }
            ]
        },
        {
            legend: 'У Вас изменилось сексуальное влечение?',
            number: 21,
            data: [
                {desc: 'Я не заметил никаких изменений в своем интересе к сексу', value: 0},
                {desc: 'Секс интересует меня меньше, чем раньше', value: 1},
                {desc: 'Я почти потерял интерес к сексу', value: 2},
                {desc: 'Я полностью потерял интерес к сексу', value: 3}
            ]
        }
    ]
    const anxietyData = [
        {
            legend: 'Ощущение онемения или покалывания в теле',
            number: 1,
            data: anxietyAnswers
        },
        {
            legend: 'Ощущение жары',
            number: 2,
            data: anxietyAnswers
        },
        {
            legend: 'Дрожь в ногах',
            number: 3,
            data: anxietyAnswers
        },
        {
            legend: 'Неспособность расслабиться',
            number: 4,
            data: anxietyAnswers
        },
        {
            legend: 'Страх, что произойдет самое плохое',
            number: 5,
            data: anxietyAnswers
        },
        {
            legend: 'Головокружение или ощущение легкости в голове',
            number: 6,
            data: anxietyAnswers
        },
        {
            legend: 'Ускоренное сердцебиение',
            number: 7,
            data: anxietyAnswers
        },
        {
            legend: 'Неустойчивость',
            number: 8,
            data: anxietyAnswers
        },
        {
            legend: 'Ощущение ужаса',
            number: 9,
            data: anxietyAnswers
        },
        {
            legend: 'Нервозность',
            number: 10,
            data: anxietyAnswers
        },
        {
            legend: 'Дрожь в руках',
            number: 11,
            data: anxietyAnswers
        },
        {
            legend: 'Ощущение удушья',
            number: 12,
            data: anxietyAnswers
        },
        {
            legend: 'Шаткость походки',
            number: 13,
            data: anxietyAnswers
        },
        {
            legend: 'Страх утраты контроля',
            number: 14,
            data: anxietyAnswers
        },
        {
            legend: 'Затрудненность дыхания',
            number: 15,
            data: anxietyAnswers
        },
        {
            legend: 'Страх смерти',
            number: 16,
            data: anxietyAnswers
        },
        {
            legend: 'Испуг',
            number: 17,
            data: anxietyAnswers
        },
        {
            legend: 'Желудочно-кишечные расстройства',
            number: 18,
            data: anxietyAnswers
        },
        {
            legend: 'Обмороки',
            number: 19,
            data: anxietyAnswers
        },
        {
            legend: 'Приливы крови к лицу',
            number: 20,
            data: anxietyAnswers
        },
        {
            legend: 'Усиление потоотделения (не связанное с жарой)',
            number: 21,
            data: anxietyAnswers
        }
    ]
    const fondData = [
        {
            legend: 'Отметьте уровень тревоги в повседневной жизни, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень',
            type: 'range',
            number: 1,
            data: [],
        },
        {
            legend: 'Отметьте уровень депрессивного состояния, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень',
            type: 'range',
            number: 2,
            data: [],
        },
        {
            legend: 'Отметьте уровень применения навыков самопомощи, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень. ' +
                'Как часто вы используете методы и стратегии для заботы о себе и преодоления трудностей? ' +
                'Например, создаёте распорядок дня или выполняете домашние дела, даже когда вам сложно.',
            type: 'range',
            number: 3,
            data: [],
        },
        {
            legend: 'Отметьте уровень применения навыков регуляции эмоций, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень. ' +
                'Вы применяете техники и упражнения, чтобы справляться с эмоциями? ' +
                'Например, используете дыхательные упражнения для успокоения или ведёте дневник для выражения чувств.',
            type: 'range',
            number: 4,
            data: [],
        },
        {
            legend: 'Отметьте уровень социализации, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень',
            type: 'range',
            number: 5,
            data: [],
        },
        {
            legend: 'Отметьте уровень принятия своего диагноза, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень',
            type: 'range',
            number: 6,
            data: [],
        },
        {
            legend: 'Отметьте уровень удовлетворенности качеством своей жизни, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень',
            type: 'range',
            number: 7,
            data: [],
        },
        {
            legend: 'Отметьте уровень самосострадания к себе, ' +
                'где 1 - минимальный уровень, а 10 - максимальный уровень. ' +
                'Насколько вы добры и заботливы по отношению к себе? ' +
                'Например, позволяете себе отдохнуть в трудные моменты или ' +
                'поддерживаете себя ободряющими словами, как друга.',
            type: 'range',
            number: 8,
            data: [],
        },
    ]
    const [activeNumber, setActiveNumber] = useState(1)
    const [scores, setScores] = useState({})
    const [isResult, setIsResult] = useState(false)

    const agreementFunc = (value) => {
        if (value === 'false') {
            setSelectionIsDisable(false)
        }
        else {
            setSelectionIsDisable(true)
        }
    }

    const scales = {
        'anxiety': anxietyData,
        'depression': depressionData,
        'fond': fondData
    }

    return (
        <div className={cl.content}>
            <div className={typeScale ? cl.invisible : cl.agreement}>
                <h4>{"Чтобы пройти опросы, нужно подтвердить своё "}
                    <a
                        className={cl.href}
                        target="_blank"
                        href="/agreement">согласие на обработку персональных данных</a>
                    {". Результаты опросов будут видны только нашим помогающим специалистам " +
                        "и не будут опубликованы в открытых источниках."}
                </h4>
                <div className={cl.agreement_radio}>
                    {agreementData.map((row) => (
                        <div
                            key={row.value}
                        >
                            <input
                                type="radio"
                                name='agreement'
                                id={row.legend}
                                value={row.value}
                                defaultChecked={row.checked}
                                onChange={(event) => {
                                    agreementFunc(event.target.value)
                                }}
                            />
                            <label htmlFor="agreement">{row.legend}</label>
                        </div>
                    ))}
                </div>
            </div>
            <div className={cl.prevent}>
                <h4>Важное предупреждение: результаты тестов носят исключительно ознакомительный характер.
                    Точный диагноз может поставить только врач-психиатр при личной консультации.
                    Если вам нужна консультация психиатра, свяжитесь с нашим администратором в <a
                        style={{color: "teal", backgroundColor: "transparent"}}
                        target="_blank"
                        rel="noreferrer"
                        href="https://t.me/eq_tg_bot"> telegram.</a>
                </h4>
            </div>
            <h3>Выберите опрос</h3>
            <div className={cl.types_quest}>
                {typesData.map((row) => (
                    <div
                        key={row.value}
                    >
                        <input
                            type="radio"
                            name='type'
                            id={row.value}
                            disabled={selectionIsDisable}
                            value={row.value}
                            onChange={(event) => {
                                setScores({})
                                setActiveNumber(1)
                                setTypeScale(event.target.value)
                                setIsResult(false)
                            }}
                        />
                        <label htmlFor="depression">{row.legend}</label>
                    </div>
                ))}
            </div>

            <div className={typeScale === 'fond' ? cl.prevent : cl.invisible}>
                <h4>Тест актуального состояния очень важен не только для вас, но и для нас.
                    Благодаря нему мы сможем отслеживать вашу динамику и предлагать более актуальную помощь.
                    После прохождения теста вы не получите однозначной интерпретации, здесь всё индивидуально,
                    но вы можете делиться статистикой с вашими помогающими специалистами.
                </h4>
            </div>

            <Scales
                scaleType={typeScale}
                scaleData={ typeScale ? scales[typeScale] : []}
                activeNumber={activeNumber}
                setActiveNumber={setActiveNumber}
                scores={scores}
                setScores={setScores}
                isResult={isResult}
                setIsResult={setIsResult}
            />
        </div>
    );
};

export default Questionnaire;