import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import ClientService from "../services/ClientService";
import Loader from "../UI/loader/Loader";
import cl from './styles/UserActivation.module.css'

const UserActivation = () => {
    const params = useParams()
    const activateID = params.id
    const [backendResponse, setBackendResponse] = useState('')
    const [fetchActivate, activateIsLoading, activateError] = useFetching(
        async () => {
            const response = await ClientService.activateAccount(activateID)
            setBackendResponse(response)
        }
    )
    useEffect(() => {
        fetchActivate()
    }, []);


    return (
        <div>
            {activateIsLoading
                ? <div className={cl.loader}><Loader/></div>
                : <div className={cl.activation_response}>{backendResponse}</div>
            }
            {activateError.length
                ? <div className={cl.activation_response}>{activateError}</div>
                : void {}
            }
        </div>
        );
};

export default UserActivation;