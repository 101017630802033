import cl from './Navbar.module.css'

import React from 'react';
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";


const AuthClientNavbar = ({setIsClicked, isClicked, exit}) => {

    return (
        <div className={cl.main}>
            <header onClick={() => {setIsClicked(!isClicked)}} className={cl.header}>
                <p
                    className={isClicked ? cl.logo : `${cl.logo} ${cl.active_logo}`}
                    >Равновесие
                </p>
                <nav className={isClicked ? cl.navbar : `${cl.navbar} ${cl.active}`}
                >
                    <Link to='/client/get_stats'>Статистика</Link>
                    <Link to='/client/questionnaire'>Опросник</Link>
                    <Link to='/client/entries'>Записи</Link>
                    <Link to="/profile">Профиль</Link>
                    <Link onClick={
                        (event) => {exit(event)}
                    }
                       to="/login">Выйти
                    </Link>
                </nav>
                <div onClick={() => {setIsClicked(!isClicked)}
                }
                     className={isClicked ? cl.burger : `${cl.burger} ${cl.active}`}>
                    <span></span>
                </div>
            </header>
        </div>
    );
};

export default observer(AuthClientNavbar);