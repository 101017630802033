import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useFetching } from "../hooks/useFetching";
import ConsultantService from "../services/ConsultantService";
import { Context } from "../index";
import ClientCardByID from "../components/ClientCardByID";
import NotesByClient from "../components/NotesByClient";
import cl from "./styles/Client.module.css";
import Loader from "../UI/loader/Loader";
import MyButton from "../UI/button/MyButton";
import ConsClientEntries from "../components/ConsClientEntries";
import Uploader from "../UI/uploader/Uploader";
import axios from "axios";
import MyModal from "../UI/modal/MyModal";
import {API_URL} from "../http";


const Client = () => {
    const { store } = useContext(Context)
    const params = useParams()
    const navigate = useNavigate()
    const [clientGroupsIsActive, setClientGroupIsActive] = useState(false)
    const [addFileIsActive, setAddFileIsActive] = useState(false)
    const [client, setClient] = useState({})
    const [newNoteIsOpen, setNewNoteIsOpen] = useState(false)
    const [allNotesIsOpen, setAllNotesIsOpen] = useState(false)
    const [newNote, setNewNote] = useState('')
    const [fileAction, setFileAction] = useState('upload')
    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)

    async function downloadArchive() {
        let filename = `${client.surname}_${client.name}.zip`
       await axios.get(`${API_URL}/files/get_archive/${params.id}&${store.userID}`, {
            responseType: 'blob', withCredentials: true
        })
            .then(response => {
                return response.data
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = filename
                document.body.appendChild(a)
                a.click()
                a.remove()
            })
            .catch(error => {
                if (error.response.status === 409) {
                    setBackendResponse('У данного клиента пока что нет файлов для скачивания')
                    setModalIsActive(true)
                }
            })
    }

    const [fetchClient, isLoading, error] = useFetching(async () => {
        const userData = await ConsultantService.getClientProfile(params.id)
        setClient({ ...client, ...userData })
    })
    const [fetchNewNote, NoteIsLoading, noteError] = useFetching(async () => {
        await ConsultantService.addNote({
            client_id: client.id,
            note: newNote,
            cons_id: store.userID,
            client_name: `${client.surname} ${client.name}`,
        })
    })

    useEffect(() => {
        fetchClient()
    }, []);

    return (
        <div className={cl.client}>
            {isLoading
                ? <div className={cl.loader}><Loader /></div>

                : <div>
                    <div className={newNoteIsOpen ? cl.none : cl.btns}>
                        <MyButton
                            onClick={() => {
                                setAllNotesIsOpen(!allNotesIsOpen)
                                setClientGroupIsActive(false)
                                setAddFileIsActive(false)
                            }}
                        >Заметки</MyButton>
                        <MyButton
                            onClick={() => {
                                setClientGroupIsActive(!clientGroupsIsActive)
                                setAllNotesIsOpen(false)
                                setAddFileIsActive(false)
                            }
                        }

                        >Группы клиента</MyButton>
                        <MyButton
                            onClick={() => navigate(`/cons/client_stats/${params.id}`)}
                        >Статистика</MyButton>
                        <MyButton
                            onClick={() => {
                                setAddFileIsActive(!addFileIsActive)
                                setBackendResponse('')
                                setModalIsActive(false)
                                setClientGroupIsActive(false)
                                setAllNotesIsOpen(false)
                            }}
                        >Файлы</MyButton>
                    </div>
                    <ClientCardByID
                        client={client}
                        newNoteIsOpen={newNoteIsOpen}
                        setNewNoteIsOpen={setNewNoteIsOpen}
                        newNote={newNote}
                        setNewNote={setNewNote}
                        fetchNewNote={fetchNewNote}
                    />
                    <div className={addFileIsActive ? cl.notes : cl.none}>
                        <h3>Работа с файлами клиента</h3>
                        <div className={cl.file_action}>
                            <input
                                type="radio"
                                name='files_action'
                                id='upload'
                                defaultChecked={true}
                                onChange={(event) => {
                                    setModalIsActive(false)
                                    setFileAction(event.target.id)
                                }}
                            />
                            <label htmlFor="upload">Загрузить</label>
                            <input
                                type="radio"
                                name='files_action'
                                id='extraction'
                                onChange={(event) => {
                                    setModalIsActive(false)
                                    setFileAction(event.target.id)
                                }}
                            />
                            <label htmlFor="'extraction'">Скачать</label>
                        </div>

                    </div>
                    <div className={addFileIsActive && fileAction === 'upload' ? cl.file : cl.none}>
                        <Uploader
                            consID={store.userID}
                            clientID={client.id}
                        />
                    </div>
                    <div className={addFileIsActive && fileAction === 'extraction' ? cl.file : cl.none}>
                        <button
                            className={cl.extract}
                            onClick={() => {
                                downloadArchive()
                            }}
                        >Скачать архив
                        </button>
                    </div>
                    <div className={allNotesIsOpen ? cl.notes : cl.none}>
                        <NotesByClient
                            clientID={params.id}
                            consID={store.userID}
                            setNewNoteIsOpen={setNewNoteIsOpen}
                        />
                    </div>

                    <div className={clientGroupsIsActive ? cl.notes : cl.none}>
                        <ConsClientEntries
                            clientID={params.id}
                        ></ConsClientEntries>
                    </div>

                    <div className={cl.modal}>
                        <MyModal
                            active={modalIsActive}
                            setActive={setModalIsActive}
                            children={backendResponse}
                        />
                    </div>

                </div>
            }
        </div>
    );
};

export default Client;