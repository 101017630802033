import React, {useContext, useEffect, useState} from 'react';
import GroupCardByID from "../components/GroupCardByID";
import {useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import cl from './styles/ClientGroupCard.module.css'
import MyButton from "../UI/button/MyButton";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import MyModal from "../UI/modal/MyModal";
import Loader from "../UI/loader/Loader";
import AdminService from "../services/AdminService";


const ClientGroupCard = () => {

    const [modalIsActive, setModalIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const {store} = useContext(Context)
    const params = useParams()
    const groupID = params.id
    const [group, setGroup] = useState({})
    const now = new Date()
    const [fetchGroup, groupIsLoading, groupError] = useFetching(async () => {
        const response = await AdminService.getGroupCard(groupID)
        setGroup(response)
    })

    const [freeSeatsCount, setFreeSeatsCount] = useState(0)
    const [fetchFreeSeats, freeSeatsIsLoading, freeSeatsError] = useFetching(
        async () => {
            const response = await AdminService.getFreeSeatsOnGroup(groupID)
            setFreeSeatsCount(response)
        })

    useEffect(() => {
        fetchGroup()
        fetchFreeSeats()
    }, []);

    const [fetchEntry, entryIsLoading, entryError] = useFetching(async () => {
        const response = await AdminService.entryClientOnGroup({
            group_id: group.id,
            group_name: group.name,
            group_date: group.group_date,
            client_id: store.userID,
            cons_id: group.cons_id,
            is_presence: false
            }
        )
        setBackendResponse(response)
        setModalIsActive(true)
    })

    const [deleteEntryClients, isEntryDeleteLoading, entryDeleteError] = useFetching(
        async () => {
            const response = await AdminService.deleteEntryClientOnGroup({
                group_id: group.id,
                client_id: store.userID
            })
            setBackendResponse(response)
            setModalIsActive(true)
                })

    useEffect(() => {
        if (groupError + entryError + entryDeleteError) {
            setBackendResponse(groupError + entryError + entryDeleteError)
            setModalIsActive(true)
        }
    }, [groupError, entryError, entryDeleteError]);

    return (
        <div className={cl.group}>

            <div className={new Date(group.group_date) > now ? cl.buttons : cl.invisible}>
                <MyButton
                    onClick={() => {
                        fetchEntry()
                    }}
                >Записаться</MyButton>

                <MyButton
                    onClick={() => {
                        deleteEntryClients()
                    }}
                >Отменить запись</MyButton>

            </div>

            {groupIsLoading
                ? <div>
                    <Loader/>
                </div>
                : <div>
                    <GroupCardByID
                        group={group}
                        isLoading={groupIsLoading}
                        error={groupError}
                        freeSeats={freeSeatsCount}
                    />
                </div>
            }


            {entryIsLoading || isEntryDeleteLoading || freeSeatsIsLoading
                ? <div>
                    <Loader/>
                </div>
                : <div className={cl.modal}>
                    <MyModal
                        active={modalIsActive}
                        setActive={setModalIsActive}
                        children={backendResponse}
                    />
                </div>
            }
        </div>
    );
};

export default observer(ClientGroupCard);