import React from 'react';
import {Link} from "react-router-dom";
import cl from './styles/AdminPanel.module.css'

const AdminPanel = () => {
    return (
        <div className={cl.content}>
            <Link className={cl.link} to='/admin/users'>Пользователи</Link>
            <Link className={cl.link} to='/admin/group_directions'>Направления</Link>
            <Link className={cl.link} to='/admin/stats'>Статистика</Link>
        </div>
    );
};

export default AdminPanel;