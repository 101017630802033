import api from "../http";


export default class ConsultantService {

    static async getClients(typeOfCons, consID){
        const response = await api.get(`/consultants/get_clients/${typeOfCons}/${consID}`)
        return response.data
    }

    static async getClientProfile(clientID) {
        const response = await api.get(`/consultants/get_client_profile/${clientID}`)
        return response.data
    }

    static async addNote(userData) {
        const response = await api.post('/consultants/add_note', userData)
        return response.data
    }

    static async getNotesByClient(notesData){
        const response = await api.post('/consultants/get_notes_by_client', notesData)
        return response.data
    }

    static async getClientEntries(clientID) {
        const response = await api.get(`/admin/get_client_entries/${clientID}`)
        return response.data
    }

    static async getClientStatByQuestionnaire(clientID, period, questType='depression') {
        const response = await api.get(
            `/consultants/get_quest_client_stat/${clientID}&${period}&${questType}`)
        return response.data
    }

    static async getClientFondStat(clientID, period) {
        const response = await api.get(
            `/consultants/get_fond_client_stat/${clientID}&${period}`)
        return response.data
    }

    static async getClientStatByEntries(clientID, period) {
        const response = await api.get(
            `/consultants/get_entries_client_stat/${clientID}&${period}`)
        return response.data
    }

    static async uploadFiles(uploadData){
        const response = await api.post('/files/upload_files', uploadData)
        return response.data
    }

    static async addNewMeeting(meetingData){
        const response = await api.post('/consultants/add_new_meeting', meetingData)
        return response.data
    }

    static async changeMeeting(meetingData){
        const response = await api.post('/consultants/change_meeting', meetingData)
        return response.data
    }

    static async getAllMeetingsByCons(consID) {
        const response = await api.get(`/consultants/get_all_meetings_by_cons/${consID}`)
        return response.data
    }

    static async getMeetingByID(meetingID) {
        const response = await api.get(`/consultants/get_meeting_by_id/${meetingID}`)
        return response.data
    }

    static async changeMeetingStatus(statusData){
        const response = await api.post('/consultants/change_status_meeting_by_id', statusData)
        return response.data
    }

    static async getMeetingEnrolledList(meetingID) {
        const response = await api.get(`/consultants/get_meeting_enrolled_list/${meetingID}`)
        return response.data
    }

    static async addNewEntry(entryData){
        const response = await api.post('/consultants/add_meeting_entry', entryData)
        return response.data
    }

    static async deleteMeetingEntry(entryData){
        const response = await api.delete('/consultants/delete_meeting_entry',
            {data: entryData})
        return response.data
    }

    static async confirmMeetingEntry(entryData){
        const response = await api.post('/consultants/confirm_meeting_entry', entryData)
        return response.data
    }

    static async getGroupsStatByLeader(leaderID, period, unique) {
        const response = await api.get(
            `/consultants/get_groups_stats_by_leader/${leaderID}&${period}&${unique}`)
        return response.data
    }

    static async getMeetingStatByClient(clientID, period, typeMeeting) {
        const response = await api.get(
            `/consultants/get_meeting_stats_by_client/${clientID}&${period}&${typeMeeting}`)
        return response.data
    }
}
