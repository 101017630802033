import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Store from "./store/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
const store = new Store()

export const Context = createContext({store})

root.render(
    <Context.Provider value={{store}}>
            <App />
    </Context.Provider>

);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
