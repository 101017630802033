import React, {useContext, useEffect, useMemo, useState} from 'react';
import cl from '../pages/styles/AdminGroups.module.css'
import {Context} from "../index";
import {useFetching} from "../hooks/useFetching";
import ConsultantService from "../services/ConsultantService";
import MyModal from "../UI/modal/MyModal";
import {formatDate} from "../UI/date/dateFormat";
import MyButton from "../UI/button/MyButton";
import {useNavigate} from "react-router-dom";
import NewMeeting from "./NewMeeting";

const ConsMeetings = () => {
    const navigate = useNavigate()
    const {store} = useContext(Context)
    const now = new Date()
    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)
    const [reservedMeetingsListIsActive, setReservedMeetingsListIsActive] = useState(true)
    const [freeMeetingsListIsActive, setFreeMeetingsListIsActive] = useState(false)
    const [newMeetingIsActive, setNewMeetingIsActive] = useState(false)
    const [filterMeetings, setFilterMeetings] = useState('actual')
    const [newMeetingData, setNewMeetingData] = useState({
        name: '',
        meeting_start_date: '',
        meeting_end_date: '',
        meeting_date: '',
        start_time: '',
        end_time: '',
        description: '',
        entries_limit: 1,
        limit_is_reached: false,
        self_sign_up: true,
        cons_id: store.userID,
        type_of_meeting: store.typeOfActivity
    })
    const changeNewMeetingData = (label, event) => {
        switch (label) {
            case 'name':
                setNewMeetingData({ ...newMeetingData, name: event.target.value });
                break;
            case 'meeting_date':
                setNewMeetingData({ ...newMeetingData, meeting_date: event.target.value });
                break;
            case 'start_time':
                setNewMeetingData({ ...newMeetingData, start_time: event.target.value });
                break;
            case 'end_time':
                setNewMeetingData({ ...newMeetingData, end_time: event.target.value });
                break;
            case 'description':
                setNewMeetingData({ ...newMeetingData, description: event.target.value });
                break;
            case 'self_sign_up':
                setNewMeetingData({ ...newMeetingData, self_sign_up: event.target.checked });
                break;
        }
    }

    const [fetchNewMeeting, newMeetingIsLoading, newMeetingError] = useFetching(
        async () => {
        setModalIsActive(false)
        if (!newMeetingData.name || !newMeetingData.description) {
            setBackendResponse('Заполните название и описание встречи')
        }
        else if (!newMeetingData.meeting_date || !newMeetingData.start_time || !newMeetingData.end_time) {
            setBackendResponse('Укажите дату и время начала и окончания встречи')
        }
        else {
            const response = await ConsultantService.addNewMeeting({
                ...newMeetingData,
                meeting_start_date: `${newMeetingData.meeting_date}T${newMeetingData.start_time}`,
                meeting_end_date: `${newMeetingData.meeting_date}T${newMeetingData.end_time}`
            })
            setBackendResponse(response)
        }
        setModalIsActive(true)
    })

    const [allMeetings, setAllMeetings] = useState([])
    const [fetchAllMeetings, allMeetingsIsLoading, allMeetingsError] = useFetching(
        async () => {
        const response = await ConsultantService.getAllMeetingsByCons(store.userID)
        setAllMeetings(response)
    })

    useEffect(() => {
        fetchAllMeetings()
    }, []);

    const filteredMeetings = useMemo(() => {

        if (filterMeetings === 'actual') {
            return allMeetings.filter(meeting => (
                (Date.parse(meeting.meeting_end_date) > Date.parse(now))
                && meeting.is_active
                && (reservedMeetingsListIsActive ? meeting.limit_is_reached : !meeting.limit_is_reached)
            ))
        }
        else if (filterMeetings === 'old') {
            return allMeetings.filter(meeting => (
                (Date.parse(meeting.meeting_end_date) < Date.parse(now))
                && meeting.is_active
                && (reservedMeetingsListIsActive ? meeting.limit_is_reached : !meeting.limit_is_reached)
            ))
        }
        else if (filterMeetings === 'inactive') {
            return allMeetings.filter(meeting => (
                !meeting.is_active
                && (reservedMeetingsListIsActive ? meeting.limit_is_reached : !meeting.limit_is_reached)
            ))
        }
    }, [allMeetings, filterMeetings, freeMeetingsListIsActive, reservedMeetingsListIsActive])

    return (
        <div className={cl.meetings}>
            <div className={cl.cons_buttons}>
                <button
                    className={reservedMeetingsListIsActive ? cl.cons_button_active : cl.cons_button_inactive}
                    onClick={() => {
                        setNewMeetingIsActive(false)
                        setFreeMeetingsListIsActive(false)
                        setReservedMeetingsListIsActive(true)
                    }}
                >Забронированные слоты
                </button>
                <button
                    className={freeMeetingsListIsActive ? cl.cons_button_active : cl.cons_button_inactive}
                    onClick={() => {
                        setNewMeetingIsActive(false)
                        setReservedMeetingsListIsActive(false)
                        setFreeMeetingsListIsActive(true)
                    }}
                >Ваши свободные слоты
                </button>
                <button
                    className={newMeetingIsActive ? cl.cons_button_active : cl.cons_button_inactive}
                    onClick={() => {
                        setReservedMeetingsListIsActive(false)
                        setFreeMeetingsListIsActive(false)
                        setNewMeetingIsActive(true)
                    }}
                >Добавить свободный слот
                </button>
            </div>
            <div className={reservedMeetingsListIsActive || freeMeetingsListIsActive ? cl.plug : cl.cons_group_invisible}>
                <div className={cl.checkbox}>
                    <div>
                        <input type="radio"
                               name='filter'
                               value='actual'
                               defaultChecked={true}
                               onClick={(event) => {
                                   setFilterMeetings(event.target.value)
                               }}
                        />
                        <p>Актуальные встречи</p>
                    </div>
                    <div>
                        <input type="radio"
                               name='filter'
                               value='old'
                               onClick={(event) => {
                                   setFilterMeetings(event.target.value)
                               }}
                        />
                        <p>Прошедшие встречи</p>
                    </div>
                    <div>
                        <input type="radio"
                               name='filter'
                               value='inactive'
                               onClick={(event) => {
                                   setFilterMeetings(event.target.value)
                               }}
                        />
                        <p>Деактивированные встречи</p>
                    </div>
                </div>
                {filteredMeetings.length
                    ?
                    filteredMeetings.map((meeting) => (
                        <div
                            className={cl.group_list}
                            key={meeting.id}
                        >
                            <p>
                                {formatDate(meeting.meeting_start_date)}
                            </p>
                            <MyButton
                                style={{border: 'none', height: '20px'}}
                                onClick={() => {
                                    navigate(`/cons/meeting/${meeting.id}`)
                                }}
                            >{meeting.name}</MyButton>

                            <hr/>
                        </div>
                    ))
                    :
                    <h4>Встречи не найдены</h4>
                }
            </div>
            <NewMeeting
                isActive={newMeetingIsActive}
                changeNewMeetingData={changeNewMeetingData}
                createFunction={fetchNewMeeting}
                buttonName='Создать'
            />
            <div className={cl.meetings}>
                <MyModal
                    active={modalIsActive}
                    setActive={setModalIsActive}
                    children={backendResponse}
                />
            </div>
        </div>
    );
};

export default ConsMeetings;