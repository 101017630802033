import React, {useEffect, useState} from 'react';
import cl from "../pages/styles/AdminGroupDirections.module.css";
import MyInput from "../UI/input/MyInput";
import Loader from "../UI/loader/Loader";
import MyModal from "../UI/modal/MyModal";
import MyButton from "../UI/button/MyButton";
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";

const AdminCreateNewGroupDir = ({newDirectionIsActive}) => {

    const [modalIsActive, setModalIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')

    const [newDirectionData, setNewDirectionData] = useState({
        name: '',
        is_individual: false
    })
    const [fetchNewDir, fetchNewDirIsLoading, fetchNewDirError] = useFetching(
        async () => {
            setModalIsActive(false)
            setBackendResponse('')
            const response = await AdminService.addNewGroupDirection(newDirectionData)
            setBackendResponse(response)
            setModalIsActive(true)
        })

    useEffect(() => {
        if (fetchNewDirError.length) {
            setBackendResponse(fetchNewDirError)
            setModalIsActive(true)
        }
    }, [fetchNewDirError]);

    useEffect(() => {
        setNewDirectionData({
            name: '',
            is_individual: false,
            entries_limit: 5
        })
    }, [newDirectionIsActive]);

    return (
        <div className={newDirectionIsActive ? cl.new_dir : cl.invisible}>
            <div className={cl.direction_content}>
                <h3>Создание нового направления</h3>
                <MyInput
                    placeholder='Название направления'
                    value={newDirectionData.name}
                    onChange={(event) => {
                        setNewDirectionData({...newDirectionData, name: event.target.value})
                    }}
                />
                {/*<div className={cl.check_box}>*/}
                {/*    <input*/}
                {/*        type='checkbox'*/}
                {/*        defaultChecked={newDirectionData.is_individual}*/}
                {/*        onChange={(event) => {*/}
                {/*            setNewDirectionData({...newDirectionData, is_individual: event.target.checked})*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <p>Индивидуальная встреча</p>*/}
                {/*</div>*/}
                <div className={cl.limit}>
                    <p>Лимит участников</p>
                    <input
                        type="number"
                        defaultValue={5}
                        onChange={(event) => {
                            setNewDirectionData({...newDirectionData, entries_limit: event.target.value})
                        }}
                    />
                </div>

                {fetchNewDirIsLoading
                    ? <div><Loader/></div>
                    : <div className={cl.modal}><MyModal
                        active={modalIsActive}
                        setActive={setModalIsActive}
                        children={backendResponse}
                    /></div>
                }

                <div className={cl.btn}>
                    <MyButton
                        onClick={() => {
                            fetchNewDir()
                        }}
                    >Создать</MyButton>
                    <MyButton
                        onClick={() => {
                            document.location.reload()
                        }}
                    >Закрыть</MyButton>
                </div>
            </div>
        </div>
    );
};

export default AdminCreateNewGroupDir;