import React, {useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import ConsultantService from "../services/ConsultantService";
import cl from "../pages/styles/Client.module.css";
import Loader from "../UI/loader/Loader";
import {formatDate} from "../UI/date/dateFormat";


const NotesByClient = ({clientID, consID, setNewNoteIsOpen}) => {
    const [notes, setNotes] = useState([])
    const [notesArray, isLoading, error] = useFetching(async () => {
        const notesData = {
            cons_id: consID,
            client_id: clientID}
        const response = await ConsultantService.getNotesByClient(notesData)
        setNotes([...notes, ...response])
    })

    useEffect(() => {
        if (clientID && consID) {
            notesArray()
        }
    }, [clientID, consID]);

    return (
        <div>
            <h3>Работа с заметками</h3>
            <div className={cl.add_note_button}>
                <button
                    className={cl.extract}
                    onClick={() => {
                        setNewNoteIsOpen(true)
                    }}
                >Добавить заметку</button>
            </div>
            <h3>Ваши заметки о клиенте</h3>
            {isLoading
                ? <div className={cl.loader}><Loader/></div>

                : notes.length
                    ? notes.map((note) => (
                        <div className={cl.notes}
                             key={note.id}
                        >
                            <p>{formatDate(note.create_date)}</p>
                            <h4>{note.note}</h4>
                        </div>
                    ))
                    : <p style={{color: "teal"}}>* Вы ещё не оставляли заметок по клиенту</p>
            }
        </div>
    );
};

export default NotesByClient;