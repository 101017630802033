import React, {useState} from 'react';
import {PhoneInput} from "react-international-phone";
// import 'react-international-phone/style.css';
import './internationalphone.css'


const InternationalPhone = ({change, value}) => {
    const [phoneNumber, setPhoneNumber] = useState(value);
    const [valid, setValid] = useState(true);

    const handleChange = (value) => {
        setPhoneNumber(value);
        change(value)
        setValid(validatePhoneNumber(value));
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^\+?[1-9]\d{1,14}$/;
        return phoneNumberPattern.test(phoneNumber);
    };
    return (
        <div>
            <PhoneInput
                defaultCountry="ru"
                value={value}
                onChange={(phone) => handleChange(phone)}
            />
        </div>
    );
};

export default InternationalPhone;