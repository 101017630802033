import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import {useNavigate} from "react-router-dom";
import Loader from "../UI/loader/Loader";
import MyButton from "../UI/button/MyButton";
import cl from '../pages/styles/AdminGroups.module.css'
import {formatDate} from '../UI/date/dateFormat'
import AdminService from "../services/AdminService";
import {Context} from "../index";
import cfg from "../cfg";


const ConsGroups = ({setResponse, newGroupIsActive, setNewGroupIsActive}) => {
    const {store} = useContext(Context)
    const now = new Date()
    const navigate = useNavigate()
    const [groups, setGroups] = useState([])
    const [filterGroups, setFilterGroups] = useState('actual')
    const [fetchGroups, groupsIsLoading, groupsError] =
        useFetching(async () => {
            if (store.userRoleID === cfg.consultantID) {
                const response = await AdminService.getGroupsByCons(store.userID)
                setGroups(response)
            }
            else {
                const response = await AdminService.getAllGroups()
                setGroups(response)
            }
    })
    useEffect(() => {
        fetchGroups()
    }, []);

    const filteredGroups = useMemo(() => {
        if (filterGroups === 'actual') {
            return groups.filter(group => (
                Date.parse(group.group_date) > Date.parse(now) && group.is_active
            ))
        }
        else if (filterGroups === 'old') {
            return groups.filter(group => (
                Date.parse(group.group_date) < Date.parse(now) && group.is_active
            ))
        }
        else if (filterGroups === 'inactive') {
            return groups.filter(group => (
                !group.is_active
            ))
        }
    }, [groups, filterGroups])

    useEffect(() => {
        setResponse(groupsError)
    }, [groupsError]);


    return (
        <div className={store.userRoleID === cfg.adminID ||
        (store.userRoleID === cfg.consultantID && store.typeOfActivity === 'group_leader')
            ? cl.cons_group
            : cl.cons_group_invisible
        }>
            <h3>Группы</h3>
            <div className={cl.checkbox}>
                <div>
                    <input type="radio"
                           name='filter'
                           value='actual'
                           defaultChecked={true}
                           onClick={(event) => {
                               setFilterGroups(event.target.value)
                           }}
                    />
                    <p>Актуальные группы</p>
                </div>
                <div>
                    <input type="radio"
                           name='filter'
                           value='old'
                           onClick={(event) => {
                               setFilterGroups(event.target.value)
                           }}
                    />
                    <p>Прошедшие группы</p>
                </div>
                <div>
                    <input type="radio"
                           name='filter'
                           value='inactive'
                           onClick={(event) => {
                               setFilterGroups(event.target.value)
                           }}
                    />
                    <p>Деактивированные группы</p>
                </div>
            </div>
            {groupsIsLoading
                ? <div><Loader/></div>
                : <div className={cl.cons_group}>
                    {filteredGroups.length
                        ?
                        filteredGroups.map((group) => (
                            <div
                                className={cl.group_list}
                                key={group.id}
                            >
                                <p>
                                    {formatDate(group.group_date)}
                                </p>
                                <MyButton
                                    style={{border: 'none', height: '25px'}}
                                    onClick={() => {
                                        navigate(`/cons/group/${group.id}`)
                                    }}
                                >{group.name}</MyButton>
                                <hr/>
                            </div>
                        ))

                        :
                        <h4>Группы не найдены</h4>
                    }
                </div>}
        </div>
    );
};

export default ConsGroups;