import React, {useRef, useState} from 'react';
import cl from './Uploader.module.css'
import {useFetching} from "../../hooks/useFetching";
import ConsultantService from "../../services/ConsultantService";
import Loader from "../loader/Loader";

const Uploader = ({consID, clientID}) => {
    const filePicker = useRef()
    let nextID = 0
    const [selectedFiles, setSelectedFiles] = useState(null)
    const [modalIsActive, setModalIsActive] = useState(false)
    const [modalContent, setModalContent] = useState('')
    const [uploaded, setUploaded] = useState([])
    let loadArray = []
    const [uploadFetch, uploadIsLoading, uploadError] = useFetching(
        async () => {
        for (let file in selectedFiles) {
            const formData = new FormData()
            formData.append('cons_id', consID)
            formData.append('client_id', clientID)
            formData.append('files', selectedFiles[file])
            const response = await ConsultantService.uploadFiles(formData)
            loadArray.push({id: nextID++, filename: response.name, status: response.status})
        }
        setUploaded([...loadArray])
    })

    const handleChange = (event) => {
        // event.preventDefault()
        setSelectedFiles(Object.values(event.target.files))
    }

    const handleUpload = (event) => {
        // event.preventDefault()
        setModalIsActive(false)
        if (!selectedFiles) {
            setModalContent('Выберите файлы для загрузки')
            setModalIsActive(true)
        }
        else {
            uploadFetch()
        }
    }

    const handlePick = (e) => {
        // e.preventDefault()
        filePicker.current.click()
    }

    return (
        <div>
            <div className={cl.uploader}>
                <button
                    className={cl.button}
                    onClick={handlePick}
                >
                    Выбрать файлы
                </button>
                <input
                    className={cl.hidden}
                    ref={filePicker}
                    type="file"
                    name='file'
                    id='file'
                    multiple={true}
                    onChange={
                        (event) => {
                            handleChange(event)
                        }
                    }/>
                <button
                    className={selectedFiles ? cl.button : cl.invisible}
                    onClick={(event) => {
                        handleUpload(event)
                    }}
                >Отправить
                </button>

                <div className={modalIsActive ? cl.modal : cl.invisible}>
                    {modalContent}
                </div>
            </div>

            {uploadIsLoading
                ? <div><Loader/></div>
                : <div className={cl.load_info}>
                    {uploaded
                        ? uploaded.map((fileInfo) => (
                            <div
                                className={cl.file_status}
                                key={fileInfo.id}
                            >
                                <p
                                    className={cl.filename}
                                >{fileInfo.filename}</p>
                                <p
                                    className={fileInfo.status ? cl.good_status : cl.bad_status}
                                >{fileInfo.status ? ' - ✔' : ' - ✘'}</p>
                            </div>
                        ))
                        : void {}
                    }
                </div>
            }
        </div>
    );
};

export default Uploader;