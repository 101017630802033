import React, {useState} from 'react';
import MyButton from "../UI/button/MyButton";
import cl from './styles/AdminGroupDirections.module.css'
import AdminCreateNewGroupDir from "../components/AdminCreateNewGroupDir";
import AdminGroupDirsList from "../components/AdminGroupDirsList";

const AdminGroupDirections = () => {

    const [newDirectionIsActive, setNewDirectionIsActive] = useState(false)
    const [directionsListIsActive, setDirectionsListIsActive] = useState(false)

    return (
        <div className={cl.body}>
            <div className={cl.buttons}>

                <MyButton
                    onClick={() => {
                        setNewDirectionIsActive(!newDirectionIsActive)
                        setDirectionsListIsActive(false)
                    }}
                >Создать направление</MyButton>

                <MyButton
                    onClick={() => {
                        setDirectionsListIsActive(!directionsListIsActive)
                        setNewDirectionIsActive(false)
                    }}
                >Список направлений</MyButton>

            </div>

            <AdminCreateNewGroupDir
                newDirectionIsActive={newDirectionIsActive}
            />

            <AdminGroupDirsList
                directionsListIsActive={directionsListIsActive}
            />

        </div>
    );
};

export default AdminGroupDirections;