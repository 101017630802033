import React, {useEffect, useState} from 'react';
import cl from "../pages/styles/AdminGroups.module.css";
import MyButton from "../UI/button/MyButton";
import Loader from "../UI/loader/Loader";
import MyModal from "../UI/modal/MyModal";
import {useFetching} from "../hooks/useFetching";
import MySelect from "../UI/select/MySelect";
import AdminService from "../services/AdminService";

const NewGroup = ({backendResponse, setBackendResponse, modalIsActive,
                          setModalIsActive, newGroupIsActive, setNewGroupIsActive, dirs,
                          directionsIsLoading, dirOptions, leadersOptions, groupLeaders, groupLeadersIsLoading
                      }) => {

    const [banCreateNewGroup, setBanCreateNewGroup] = useState(false)

    useEffect(() => {
        if (!dirs) {
            setBanCreateNewGroup(true)
            setBackendResponse('Создание группы запрещено. Список направлений пуст')
            setModalIsActive(true)
        }
        else if (!groupLeaders) {
            setBanCreateNewGroup(true)
            setBackendResponse('Создание группы запрещено. Список ведущих пуст')
            setModalIsActive(true)
        }
    }, []);

    // const now = new Date()
    const [fetchNewGroup, newGroupIsLoading, errorGroupCreate] =
        useFetching(async () => {
            if (!newGroupData.group_date) {
                setBackendResponse('Укажите дату проведения группы')
            }
            else {
                const response = await AdminService.addNewGroup({
                    ...newGroupData,
                    name: newGroupData.name
                                ? newGroupData.name
                                : dirOptions[0].name,
                    cons_id: newGroupData.cons_id
                                ? newGroupData.cons_id
                                : leadersOptions[0].id,
                    cons_name: newGroupData.cons_name
                                ? newGroupData.cons_name
                                : leadersOptions[0].name,
                    is_individual: false,
                    self_sign_up: true,
                    entries_limit: newGroupData.name
                                    ? dirs[newGroupData.name].entries_limit
                                    : dirOptions[0].entries_limit
                })
                setBackendResponse(response)
            }
        setModalIsActive(true)
    })

    const [newGroupData, setNewGroupData] = useState({
        name: '',
        cons_id: '',
        cons_name: '',
        description: '',
        group_date: ''
    })

    useEffect(() => {
        setBackendResponse(errorGroupCreate)
    }, [errorGroupCreate]);

    const changeGroupData = (label, event) => {
        switch (label) {
            case 'name':
                setNewGroupData({ ...newGroupData, name: event, entries_limit: dirs[event].entries_limit })
                break
            case 'cons_name':
                setNewGroupData({ ...newGroupData, cons_name: event, cons_id: groupLeaders[event] })
                break
            case 'description':
                setNewGroupData({ ...newGroupData, description: event.target.value })
                break
            case 'group_date':
                setNewGroupData({ ...newGroupData, group_date: event.target.value })
                break
        }
    }

    return (
        <div>
            <div className={newGroupIsActive
                    ? cl.create_button_disabled
                    : cl.create_button
            }>
                <MyButton
                    onClick={() => {
                        setNewGroupIsActive(true)
                    }}
                >Создать группу</MyButton>
            </div>
            {newGroupIsActive
                ? <div>
                    <div className={cl.form}>
                        {directionsIsLoading
                            ? <div><Loader/></div>
                            : <MySelect
                                options={dirOptions}
                                defaultValue='Направление'
                                value={newGroupData.name}
                                onChange={(event) => {
                                    changeGroupData('name', event)
                                }}
                            />
                        }
                        {groupLeadersIsLoading
                            ? <div><Loader/></div>
                            : <MySelect
                                options={leadersOptions}
                                defaultValue='Ведущий'
                                value={newGroupData.cons_name}
                                onChange={(event) => {
                                    changeGroupData('cons_name', event)
                                }}
                            />
                        }
                        <input
                            className={cl.date}
                            type="datetime-local"
                            placeholder='asdfas'
                            onChange={(event) => {
                                changeGroupData('group_date', event)
                            }}
                        />
                        <div className={cl.checkbox}>
                            <p>Лимит участников:</p>
                            <p>{
                                newGroupData.name
                                    ? dirs[newGroupData.name]['entries_limit']
                                    : dirs[Object.keys(dirs)[0]]['entries_limit']
                            }
                            </p>
                        </div>
                        <textarea
                            placeholder='Краткое описание группы'
                            onChange={(event) => {
                                changeGroupData('description', event)
                            }}
                        />
                        <div className={cl.btns}>
                            <MyButton
                                disabled={banCreateNewGroup}
                                onClick={() => {
                                    setModalIsActive(false)
                                    setBackendResponse('')
                                    fetchNewGroup()
                                    setModalIsActive(true)
                                }}
                            >Создать</MyButton>
                            <MyButton
                                onClick={() => {
                                    window.location.reload()
                                }}
                            >Закрыть</MyButton>
                        </div>
                        {newGroupIsLoading
                            ? <Loader/>
                            : <div className={cl.modal}>
                                <MyModal active={modalIsActive}
                                         setActive={setModalIsActive}
                                >{backendResponse}</MyModal>
                            </div>
                        }
                    </div>
                </div>
                : void {}
            }
        </div>
    );
};

export default NewGroup;