import cl from './Navbar.module.css'
import React from 'react';

const UnAuthNavbar = ({setIsClicked, isClicked}) => {
    return (
        <div>
            <header onClick={() => {
                setIsClicked(!isClicked)
            }} className={cl.header}>
                {/*<a className={isClicked ? cl.logo : `${cl.logo} ${cl.active_logo}`}*/}
                {/*   href='https://bf-ravnovesie.ru'>Равновесие</a>*/}
                <p
                    className={isClicked ? cl.logo : `${cl.logo} ${cl.active_logo}`}
                >Равновесие
                </p>
                <nav className={isClicked ? cl.navbar : `${cl.navbar} ${cl.active}`}
                >

                    <a href="/login">Войти</a>
                    <a href="/registration">Зарегистрироваться</a>

                </nav>
                <div onClick={() => {
                    setIsClicked(!isClicked)
                }
                }
                     className={isClicked ? cl.burger : `${cl.burger} ${cl.active}`}>
                    <span></span>
                </div>
            </header>
        </div>
    );
};

export default UnAuthNavbar;