import React, {useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import ConsultantService from "../services/ConsultantService";
import Loader from "../UI/loader/Loader";
import {Link} from "react-router-dom";
import cl from "../pages/styles/ClientList.module.css";
import {formatDate} from "../UI/date/dateFormat";
import AdminService from "../services/AdminService";

const ConsClientEntries = ({clientID}) => {
    const [entries, setEntries] = useState([])
    const [getEntries, entriesIsLoading, entriesError] = useFetching(
        async () => {
        const response = await ConsultantService.getClientEntries(clientID)
        setEntries(response)
    })
    useEffect(() => {
        getEntries()
    }, []);
    return (
        <div>
            <h3>Группы клиента</h3>
            {entriesIsLoading
                ? <div><Loader/></div>
                : entries.length
                        ? entries.map((row) => (
                            <div
                                key={row.id}
                            >
                                <Link
                                    // key={row.id}
                                    className={cl.client_row}
                                    to={`/cons/group/${row.group_id}`}
                                >{formatDate(row.group_date)} {row.group_name}</Link>
                            </div>
                        ))
                        : <h4>Клиент ещё не записался ни на одну группу</h4>
            }
        </div>
    );
};

export default ConsClientEntries;