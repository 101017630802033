import React, { useRef, useState } from 'react';
import { useFetching } from "../hooks/useFetching";
import ConsultantService from "../services/ConsultantService";
import cl from "../pages/styles/AdminGroupCard.module.css";
import Loader from "../UI/loader/Loader";
import MyButton from "../UI/button/MyButton";
import MyModal from "../UI/modal/MyModal";
import AdminService from "../services/AdminService";

const ConfirmEntry = ({group ,entriesList, confirmIsActive}) => {

    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)
    const checkedClients = useRef({})
    const [confirmClients, isConfirmClientsLoading, confirmClientsError] = useFetching(
        async () => {
        for (let id in checkedClients) {
            if (checkedClients[id] && id !== 'current') {
                const response = await AdminService.confirmEntry({
                    group_id: group.id,
                    client_id: id,
                    is_presence: true
                })
                setBackendResponse(response)
            }
            setModalIsActive(true)
        }
    })

    return (
        <div className={cl.clients}>

            <div className={cl.headers_btn}>

                <MyButton
                    onClick={() => {
                        confirmClients()
                    }}
                >Подтвердить
                </MyButton>

                <MyButton
                    onClick={() => {
                        document.location.reload()
                    }}
                >Закрыть</MyButton>
            </div>

            <h3>Список записавшихся</h3>
            {(backendResponse || confirmClientsError) &&
                <div className={cl.modal}>
                    <MyModal
                        active={modalIsActive}
                        setActive={setModalIsActive}
                        children={`${backendResponse}${confirmClientsError}`}
                    />
                </div>
            }

            {isConfirmClientsLoading
                ? <div className={cl.loader}><Loader/></div>
                : <div>
                    {entriesList.length
                        ? entriesList.map((row) => (
                            <div
                                key={row.client_id}
                                className={cl.checkbox_client_list}
                            >
                                <input
                                    type='checkbox'
                                    id={row.client_id}
                                    disabled={row.is_presence}
                                    defaultChecked={
                                        row.client_id in checkedClients
                                            ? checkedClients[row.client_id]
                                            : row.is_presence
                                    }
                                    onClick={(event) => {
                                        checkedClients[event.target.id] = event.target.checked
                                    }}
                                />
                                <p>{row.client_name}</p>
                            </div>
                        ))
                        : <h4>Клиенты не найдены</h4>
                    }
                </div>
            }
        </div>
    );
};

export default ConfirmEntry;