import React from 'react';
import {formatDate} from "../UI/date/dateFormat";
import cl from '../pages/styles/ConsMeetingCard.module.css'

const MeetingCardById = ({meeting}) => {
    return (
        <div className={cl.meeting_card}>
            <div>
                <p>Название:</p>
                <h4>{meeting.name}</h4>
            </div>
            <hr/>
            <div>
                <p>Дата начала:</p>
                <h4>{formatDate(meeting.meeting_start_date)}</h4>
            </div>
            <hr/>
            <div>
                <p>Дата окончания:</p>
                <h4>{formatDate(meeting.meeting_end_date)}</h4>
            </div>
            <hr/>
            <div>
                <p>Описание:</p>
                <h4>{meeting.description}</h4>
            </div>
            <hr/>
            <div>
                <p>Самозапись:</p>
                <h4>{meeting.self_sign_up ? 'Да' : 'Нет'}</h4>
            </div>
            <hr/>
            <div>
                <p>Статус встречи:</p>
                <h4>{meeting.is_active ? 'Активна' : 'Деактивирована'}</h4>
            </div>
        </div>
    );
};

export default MeetingCardById;