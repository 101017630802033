import React from 'react';
import picture from '../static/images/agreement.png'
import cl from './styles/Questionaire.module.css'

const Agreement = () => {
    return (
        <div className={cl.picture}>
            <img src={picture} alt=""/>
        </div>
    );
};

export default Agreement;