import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFetching } from "../hooks/useFetching";
import cl from "../pages/styles/AdminGroupCard.module.css";
import MyInput from "../UI/input/MyInput";
import Loader from "../UI/loader/Loader";
import MyButton from "../UI/button/MyButton";
import MyModal from "../UI/modal/MyModal";
import AdminService from "../services/AdminService";

const EntryClientOnGroup = ({ group }) => {
    const [clients, setClients] = useState([])
    const [searchQuery, setSearchQuery] = useState('')
    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)
    const [fetchClients, isClientsLoading, clientsError] = useFetching(async () => {
        setBackendResponse('')
        setModalIsActive(false)
        const response = await AdminService.getClients()
        setClients(response)
    })

    const checkedClients = useRef({})

    useEffect(() => {
        fetchClients()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredClients = useMemo(() => {
        return clients.filter(user => (
            `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
            ) || checkedClients[user.id] === true))
    }, [searchQuery, clients])

    const [entryClients, isEntryLoading, entryError] = useFetching(async () => {
        setBackendResponse('')
        setModalIsActive(false)
        for (let id in checkedClients) {
            if (checkedClients[id] && id !== 'current') {
                setBackendResponse('')
                const response = await AdminService.entryClientOnGroup({
                    group_id: group.id,
                    group_date: group.group_date,
                    group_name: group.name,
                    client_id: id,
                    cons_id: group.cons_id,
                    is_presence: false
                })
                setBackendResponse(response)
            }
            setModalIsActive(true)
        }
    })

    const [deleteEntryClients, isEntryDeleteLoading, entryDeleteError] = useFetching(
        async () => {
            setBackendResponse('')
            setModalIsActive(false)
            for (let id in checkedClients) {
                if (checkedClients[id] && id !== 'current') {
                    setBackendResponse('')
                    const response = await AdminService.deleteEntryClientOnGroup({
                        group_id: group.id,
                        client_id: id
                    })
                    setBackendResponse(response)
                }
                setModalIsActive(true)
            }
        })

    useEffect(() => {
        setBackendResponse('')
        if (clientsError.length) {
            setBackendResponse(clientsError)
            setModalIsActive(true)
        }
        else if (entryError.length) {
            setBackendResponse(entryError)
            setModalIsActive(true)
        }
        else if (entryDeleteError.length) {
            setBackendResponse(entryDeleteError)
            setModalIsActive(true)
        }
    }, [clientsError, entryError, entryDeleteError]);

    return (
        <div className={cl.clients}>
            <MyInput
                placeholder='Поиск клиентов...'
                value={searchQuery}
                onChange={(e) => {
                    setSearchQuery(e.target.value)
                }} />

            <div className={cl.headers_btn}>

                <MyButton
                    onClick={() => {
                        entryClients()
                    }}
                >Записать
                </MyButton>

                <MyButton
                    onClick={() => {
                        deleteEntryClients()
                    }}
                >Удалить
                </MyButton>

                <MyButton
                    onClick={() => {
                        document.location.reload()
                    }}
                >Закрыть</MyButton>
            </div>

            {modalIsActive &&
                <div className={cl.modal}>
                    <MyModal
                        active={modalIsActive}
                        setActive={setModalIsActive}
                        children={backendResponse}
                    />
                </div>
            }

            {isClientsLoading || isEntryDeleteLoading || isEntryLoading
                ? <div className={cl.loader}><Loader /></div>
                : <div>
                    {filteredClients.length
                        ? filteredClients.map((row) => (
                            <div
                                key={row.id}
                                className={cl.checkbox_client_list}
                            >
                                <input
                                    type={group.is_individual ? 'radio' : 'checkbox'}
                                    name='individual'
                                    id={row.id}
                                    defaultChecked={
                                        row.id in checkedClients
                                            ? checkedClients[row.id]
                                            : false
                                    }
                                    onClick={(event) => {
                                        if (group.is_individual) {
                                            for (let id in checkedClients) {
                                                if (checkedClients[id] && id !== 'current') {
                                                    checkedClients[id] = false
                                                }
                                            }
                                        }
                                        checkedClients[event.target.id] = event.target.checked
                                    }}

                                />
                                <p>{row.surname} {row.name}</p>
                            </div>
                        ))
                        : <h4>Клиенты не найдены</h4>
                    }
                </div>
            }
        </div>
    );
};

export default EntryClientOnGroup;