import React, {useEffect, useState} from 'react';
import MySelect from "../UI/select/MySelect";
import cl from '../pages/styles/AdminDirectionCard.module.css'
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import Loader from "../UI/loader/Loader";
import {formatDate} from "../UI/date/dateFormat";

const AdminDirStat = ({graph, dir}) => {
    const selectOptions = [
        {name: 'Всё время', value: 'all'},
        {name: 'Год', value: 'year'},
        {name: 'Месяц', value: 'month'},
        {name: 'Диапазон', value: 'range'},
    ]
    const [statGraph, setStatGraph] = useState(graph)
    const [groupsList, setGroupsList] = useState([])
    const [allStat, setAllStat] = useState({})
    const [selectValue, setSelectValue] = useState('all')
    const [rangeDate, setRangeDate] = useState({
        start: '1970-01-01',
        end: '2222-12-31'
    })

    const [allOrUnique, setAllOrUnique] = useState('all')

    const [fetchStat, statIsLoading, statError] = useFetching(async () => {
        const response = await AdminService.getDirectionStatistic(dir, selectValue === 'range'
            ? rangeDate.start + '_' + rangeDate.end
            : selectValue
            , allOrUnique
            )
        setStatGraph(response.graph)
        setGroupsList(response.groups)
        setAllStat(response.all_stat)
    })

    useEffect(() => {
        fetchStat()
    }, [selectValue, rangeDate, allOrUnique, dir]);

    return (
        <div>
            <h2>Статистика</h2>
            <MySelect
                options={selectOptions}
                defaultValue='Период'
                value={selectValue}
                onChange={setSelectValue}
            />
            <div className={selectValue === 'range' ? cl.range : cl.invisible}>
                <p>с</p>
                <input
                    type={"date"}
                    className={cl.input_date}
                    onChange={e =>
                        setRangeDate({...rangeDate, start: e.target.value})}
                />
                <p>по</p>
                <input
                    type={"date"}
                    className={cl.input_date}
                    onChange={e =>
                        setRangeDate({...rangeDate, end: e.target.value})}
                />
            </div>
            <div className={cl.unique_radio}>
                <div>
                    <input type="radio"
                           name='unique_radio'
                           value='all'
                           defaultChecked={true}
                           id='all'
                        onChange={(event) => {
                            setAllOrUnique(event.target.value)
                        }}
                    />
                    <label htmlFor="all">Все записи</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name='unique_radio'
                        value='unique'
                        id='unique'
                        onChange={(event) => {
                            setAllOrUnique(event.target.value)
                        }}
                    />
                    <label htmlFor="unique">Уникальные записи</label>
                </div>
            </div>
            {statIsLoading
                ? <Loader/>
                : <img className={cl.stat_graph} src={statGraph} alt=''/>
            }
            <div className={cl.all_stat}>
                <h3>Общая статистика</h3>
                <p>Количество групп: {allStat.groups_count}</p>
                <p>Число записей: {allStat.entries_count}</p>
                <p>Подтверждено: {allStat.presence_entries_count}</p>
            </div>
            <div className={cl.groups}>
                <h3>Группы</h3>
                {statIsLoading
                    ? <div><Loader/></div>
                    : groupsList.length
                        ? groupsList.map((group) => (
                            <div
                                key={group.group_id}
                            >
                                <a
                                    className={cl.groups_list}
                                    href={`/cons/group/${group.group_id}`}>
                                    {formatDate(group.group_date)} -
                                    записи: {group.entries_count},
                                    подтверждено: {group.presence_entries_count}
                                </a>
                            </div>
                        ))
                        : <p>Группы не найдены</p>

                }
            </div>

        </div>
    );
};

export default AdminDirStat;