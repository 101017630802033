import React, {useEffect, useState} from 'react';
import cl from "../pages/styles/AdminUserCard.module.css";
import MySelect from "../UI/select/MySelect";
import Loader from "../UI/loader/Loader";
import {formatDate} from "../UI/date/dateFormat";
import MyButton from "../UI/button/MyButton";
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import {useNavigate} from "react-router-dom";
import MyModal from "../UI/modal/MyModal";

const AdminMeetingStatByCons = ({userID}) => {
    const [modalIsActive, setModalIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const navigate = useNavigate()
    const [allOrUnique, setAllOrUnique] = useState('all')
    const [rangeDate, setRangeDate] = useState({
        start: '1970-01-01',
        end: '2222-12-31'
    })
    const selectOptions = [
        {name: 'Всё время', value: 'all'},
        {name: 'Год', value: 'year'},
        {name: 'Месяц', value: 'month'},
        {name: 'Диапазон', value: 'range'},
    ]
    const [selectValue, setSelectValue] = useState('all')
    const [statGraph, setStatGraph] = useState('')
    const [meetingsList, setMeetingsList] = useState([])
    const [allStat, setAllStat] = useState({})

    useEffect(() => {
        fetchStat()
    }, [selectValue, rangeDate, userID, allOrUnique]); // eslint-disable-line react-hooks/exhaustive-deps

    const [fetchStat, statIsLoading, statError] = useFetching(async () => {
        setBackendResponse('')
        setModalIsActive(false)
        const response = await AdminService.getMeetingsByConsStatistic(userID, selectValue === 'range'
            ? rangeDate.start + '_' + rangeDate.end
            : selectValue
            ,allOrUnique
        )
        setStatGraph(response.graph)
        setMeetingsList(response.meetings)
        setAllStat(response.all_stat)
    })

    useEffect(() => {
        if (statError.length) {
            setBackendResponse(statError)
            setModalIsActive(true)
        }
    }, [statError]);

    return (
        <div>
            <h3>Статистика по встречам:</h3>
            <div>
                <MySelect
                    options={selectOptions}
                    defaultValue='Период'
                    value={selectValue}
                    onChange={setSelectValue}
                />
                <div className={selectValue === 'range' ? cl.range : cl.invisible}>
                    <p>с</p>
                    <input
                        type={"date"}
                        className={cl.input_date}
                        onChange={e =>
                            setRangeDate({...rangeDate, start: e.target.value})}
                    />
                    <p>по</p>
                    <input
                        type={"date"}
                        className={cl.input_date}
                        onChange={e =>
                            setRangeDate({...rangeDate, end: e.target.value})}
                    />
                </div>
                <div className={cl.unique_radio}>
                    <div>
                        <input type="radio"
                               name='unique_radio'
                               value='all'
                               defaultChecked={true}
                               id='all'
                               onChange={(event) => {
                                   setAllOrUnique(event.target.value)
                               }}
                        />
                        <label htmlFor="all">Все записи</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name='unique_radio'
                            value='unique'
                            id='unique'
                            onChange={(event) => {
                                setAllOrUnique(event.target.value)
                            }}
                        />
                        <label htmlFor="unique">Уникальные записи</label>
                    </div>
                </div>
                {statIsLoading
                    ? <Loader/>
                    : <img className={meetingsList.length ? cl.graph : cl.invisible} src={statGraph} alt=''/>
                }

                {modalIsActive &&
                    <div>
                        <MyModal
                            active={modalIsActive}
                            setActive={setModalIsActive}
                            children={backendResponse}
                        />
                    </div>
                }

                <div className={meetingsList.length ? cl.plug : cl.invisible}>
                    <h3>Общая статистика:</h3>
                    <p>Количество встреч: {allStat.meetings_count}</p>
                    <p>Количество записей: {allStat.entries_count}</p>
                    <p>Записей подтверждено: {allStat.presence_entries_count}</p>
                </div>
                <h3>Встречи:</h3>
                {meetingsList.length
                    ?
                    meetingsList.map((meeting) => (
                        <div
                            className={cl.group_list}
                            key={meeting.meeting_id}
                        >
                            <p>
                                {formatDate(meeting.meeting_start_date)}
                            </p>
                            <MyButton
                                style={{border: 'none', height: '40px'}}
                                onClick={() => {
                                    navigate(`/cons/meeting/${meeting.meeting_id}`)
                                }}
                            >{meeting.meeting_name},
                                записано: {meeting.entries_count},
                                подтверждено: {meeting.presence_entries_count}</MyButton>

                            <hr className={cl.hr}/>
                        </div>
                    ))
                    :
                    <h4>Встречи не найдены</h4>
                }
            </div>
        </div>
    );
};

export default AdminMeetingStatByCons;