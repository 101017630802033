import api from "../http";


export default class UserService {
    static async profile(email){
        return await api.get('/users/profile', email)
    }

    static async profileByUserID(userID){
        const response = await api.get(`/users/get_user_profile/${userID}`)
        return response.data
    }

    static async updateProfile(userData){
        return await api.post('/users/update_profile', userData)
    }

    static async changePassword(userData){
        return await api.post('/users/change_password', userData)
    }

    static async forgotPassword(userData){
        return await api.post('/users/forgot_password', userData)
    }
}