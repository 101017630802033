import api from "../http";


export default class AdminService {
    static async addNewGroupDirection(directionData) {
        const response = await api.post('/admin/create_direction', directionData)
        return response.data
    }

    static async getAllGroupDirections() {
        const response = await api.get('/admin/get_directions')
        return response.data
    }

    static async getOneDirection(directionID) {
        const response = await api.get(`/admin/get_one_direction/${directionID}`)
        return response.data
    }

    static async changeDirection(directionData) {
        const response = await api.post('/admin/change_direction', directionData)
        return response.data
    }

    static async activateDirection(directionData) {
        const response = await api.post('/admin/activation_direction', directionData)
        return response.data
    }

    static async deactivateDirection(directionData) {
        const response = await api.post('/admin/deactivation_direction', directionData)
        return response.data
    }

    static async getUsers() {
        const response = await api.get('/admin/get_users')
        return response.data
    }

    static async getAllUsers() {
        const response = await api.get('/admin/get_all_users')
        return response.data
    }

    static async getClients() {
        const response = await api.get('/admin/get_clients')
        return response.data
    }

    static async getUserProfile(userID) {
        const response = await api.get(`/admin/get_user_profile/${userID}`)
        return response.data
    }

    static async changeUserCard(userData) {
        const response = await api.post(`/admin/change_user_card`, userData)
        return response.data
    }

    static async getDirectionStatistic(directionID, period, unique) {
        const response = await api.get(
            `/admin/get_dir_statistic/${directionID}&${period}&${unique}`)
        return response.data
    }

    static async getAllGroupLeadersForSelect() {
        const response = await api.get(`/admin/get_group_leaders_for_select`)
        return response.data
    }

    static async addNewGroup(groupData){
        const response = await api.post('/admin/add_new_group', groupData)
        return response.data
    }

    static async changeGroupStatus(changeData){
        return  await api.post('/admin/change_group_status', changeData)
    }

    static async getAllGroups() {
        const response = await api.get('/admin/get_all_groups')
        return response.data
    }

    static async getGroupsByCons(consID) {
        const response = await api.get(`/admin/get_groups_by_cons/${consID}`)
        return response.data
    }

    static async getGroupCard(groupID) {
        const response = await api.get(`/admin/get_cons_group/${groupID}`)
        return response.data
    }

    static async getFreeSeatsOnGroup(groupID) {
        const response = await api.get(`/admin/get_free_seat_count_on_group/${groupID}`)
        return response.data
    }

    static async entryClientOnGroup(entryData){
        const response = await api.post('/admin/entry_client_on_group', entryData)
        return response.data
    }

    static async deleteEntryClientOnGroup(entryData){
        const response = await api.delete(
            '/admin/del_entry', {data: entryData})
        return response.data
    }

    static async getEnrolledClients(groupID) {
        const response = await api.get(`/admin/get_list_entries/${groupID}`)
        return response.data
    }

    static async confirmEntry(entryData){
        const response = await api.post('/admin/confirm_entries', entryData)
        return response.data
    }

    static async getDirections() {
        const response = await api.get('/admin/get_active_directions')
        return response.data
    }

    static async getMeetingsByConsStatistic(consID, period, unique) {
        const response = await api.get(
            `/admin/get_meetings_stat_by_cons/${consID}&${period}&${unique}`)
        return response.data
    }

    static async getRK() {
        const response = await api.get('/admin/get_all_rk')
        return response.data
    }

    static async getPsycho() {
        const response = await api.get('/admin/get_all_psycho')
        return response.data
    }

    static async verification(verificationData){
        const response = await api.post('/admin/verification', verificationData)
        return response.data
    }
}