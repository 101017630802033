import axios from "axios";


//dev connection
// export const API_URL = 'http://127.0.0.1:8000'

// prod connection
export const API_URL = 'https://bf-ravnovesie.ru:8765'

const api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
    headers: ['*']
})

api.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
    return config;
})

export default api;