import api from "../http";


export default class AuthService {
    static async login(userData){
        return await api.post('/users/login', userData)
    }

    static async registration(userData){
        const response = await api.post('/users/registration', userData)
        return response.data
    }


    static async logOut(){
        return await api.post('/users/logout')
    }
}