import React from 'react';
import ClientMeetings from "../components/ClientMeetings";

const MeetingsPsycho = () => {
    return (
        <div>
            <ClientMeetings
                typeOfMeeting='psycho'
            />
        </div>
    );
};

export default MeetingsPsycho;