import React from 'react';
import cl from "../pages/styles/AdminGroups.module.css";

const NewMeeting = ({isActive, changeNewMeetingData, createFunction,
                        buttonName, meetingData, repeat=false}) => {
    return (
        <div className={isActive ? cl.new_meeting : cl.cons_group_invisible}>
            <div className={cl.new_meeting_row}>
                <p>Название встречи:</p>
                <input
                    defaultValue={meetingData ? meetingData.name : ''}
                    type="text"
                    placeholder='Например, "Консультация с Васильевой Оксаной"'
                    onChange={(event) => {
                        changeNewMeetingData('name', event)
                    }}
                />
            </div>
            <div className={cl.new_meeting_row}>
                <p>Дата встречи:</p>
                <input
                    defaultValue={meetingData && !repeat ? meetingData.meeting_date : ''}
                    type="date"
                    onChange={(event) => {
                        changeNewMeetingData('meeting_date', event)
                    }}
                />
            </div>
            <div className={cl.new_meeting_row}>
                <p>Время начала:</p>
                <input
                    defaultValue={meetingData && !repeat ? meetingData.start_time : ''}
                    type="time"
                    onChange={(event) => {
                        changeNewMeetingData('start_time', event)
                    }}
                />
            </div>
            <div className={cl.new_meeting_row}>
                <p>Время окончания:</p>
                <input
                    defaultValue={meetingData && !repeat ? meetingData.end_time : ''}
                    type="time"
                    onChange={(event) => {
                        changeNewMeetingData('end_time', event)
                    }}
                />
            </div>
            <div className={cl.new_meeting_row}>
                <p>Краткое описание:</p>
                <input
                    defaultValue={meetingData ? meetingData.description : ''}
                    type="text"
                    placeholder='Краткое описание встречи'
                    onChange={(event) => {
                        changeNewMeetingData('description', event)
                    }}
                />
            </div>
            <div className={cl.self_entry}>
                <input
                    type="checkbox"
                    name='selfentry'
                    onChange={(event) => {
                        changeNewMeetingData('self_sign_up', event)
                    }}
                />
                <label htmlFor="selfentry">Клиент может самостоятельно записаться на встречу</label>
            </div>
            <div className={cl.new_meeting_buttons}>
                <button
                    onClick={() => {
                        createFunction()
                    }}
                >{buttonName}
                </button>
                <button
                    onClick={() => {
                        window.location.reload()
                    }}
                >Закрыть
                </button>
            </div>
        </div>
    );
};

export default NewMeeting;