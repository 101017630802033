import {makeAutoObservable} from "mobx";
import AuthService from "../services/AuthService";
import axios from "axios";
import {API_URL} from "../http";


export default class Store {
    userID = ''
    userRoleID = ''
    userEmailActivated = false
    isVerified = false
    isAuth = false
    typeOfActivity = null
    isSuperUser = null
    constructor() {
        makeAutoObservable(this)
    }

    setIsAuth(value) {
        this.isAuth = value
    }

    setUserData(user) {
        this.userID = user.user_id
        this.userRoleID = user.role_id
        this.userEmailActivated = user.email_activated
        this.isVerified = user.is_verified
        this.typeOfActivity = user.type_of_activity
        this.isSuperUser = user.is_superuser
    }

    removeUserData() {
        this.userID = ''
        this.userRoleID = ''
        this.userEmailActivated = false
        this.isVerified = false
        this.typeOfActivity = null
        this.isSuperUser = null
    }

    async login(userData) {
            const response = await AuthService.login(userData);
            localStorage.setItem('token', response.data.user_access_token);
            this.setIsAuth(true)
            this.setUserData(response.data.user_data)
    }

    async logOut() {
        try {
            await AuthService.logOut();
            localStorage.removeItem('token');
            this.setIsAuth(false)
            this.removeUserData()
        } catch (error) {
            console.log(error.response?.data?.detail)
        }
    }

    async checkAuth() {
        try {
            const response = await axios.get(
                `${API_URL}/users/refresh_token`,
                {withCredentials: true}
            )
            localStorage.setItem('token', response.data.user_access_token);
            this.setIsAuth(true)

            this.setUserData(response.data.user_data)

        } catch (error) {
            console.log(error.response?.data?.detail)
        }

    }
}