import React, {useContext, useState} from 'react';
import cl from './Navbar.module.css';
import {useNavigate} from "react-router-dom";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import UnAuthNavbar from "./UnAuthNavbar";
import AuthConsultantNavbar from "./AuthConsultantNavbar";
import AuthClientNavbar from "./AuthClientNavbar";
import AuthAdminNavbar from "./AuthAdminNavbar";
import Config from "../cfg";
import UnverifiedClientNavbar from "./UnverifiedClientNavbar";



const Navbar = () => {
    const navigate = useNavigate()
    const [isClicked, setIsClicked] = useState(true)
    const [scrollPosition, setScrollPosition] = useState(0)
    const header = document.querySelector('header')
    const {store} = useContext(Context)

    const exit = async (e) => {
        e.preventDefault()

        try {
            await store.logOut()
            navigate('/login')
            // localStorage.removeItem('profile')
        } catch (error) {
            console.log(error)
        }
    }

    const containHide = () => {
        if (header) {
            return header.className.includes('hide')
        }
        else {
            return false
        }
    }

    window.addEventListener('scroll', () => {
        if (scrollPosition < window.scrollY && !containHide()) {
            setIsClicked(true)

            if (header) {
                header.className = `${cl.header} ${cl.hide}`
            }
        }
        else if (scrollPosition > window.scrollY && containHide()) {
            if (header) {
                header.className = `${cl.header}`
            }
        }
        setScrollPosition(window.scrollY)
    })

    if (store.isAuth) {

        if (store.userRoleID === Config.consultantID) {
            return <AuthConsultantNavbar isClicked={isClicked}
                                         setIsClicked={setIsClicked} exit={exit}/>
        }
        else if (store.userRoleID === Config.adminID) {
            return <AuthAdminNavbar isClicked={isClicked}
                                         setIsClicked={setIsClicked} exit={exit}/>
        }
        else if (store.userRoleID === Config.clientID && !store.isVerified) {
            return <UnverifiedClientNavbar isClicked={isClicked}
                                     setIsClicked={setIsClicked} exit={exit}/>
        }
        else return <AuthClientNavbar isClicked={isClicked}
                                     setIsClicked={setIsClicked} exit={exit}/>

    }
    else return (
        <UnAuthNavbar isClicked={isClicked} setIsClicked={setIsClicked} exit={exit}/>
    );
}

export default observer(Navbar);
