import React from 'react';
import {useNavigate} from "react-router-dom";
import cl from "../pages/styles/AdminGroupCard.module.css";
import MyButton from "../UI/button/MyButton";
import Loader from "../UI/loader/Loader";

const EnrolledClientsList = ({clients, clientsIsLoading}) => {

    const navigate = useNavigate()

    return (
        <div>
            {clientsIsLoading
                ? <div className={cl.loader}><Loader/></div>
                : <div className={cl.search_clients}>
                    <h3>Список записавшихся</h3>
                    {clients.length
                        ? clients.map((row) => (
                            <div key={row.client_id}>
                                <MyButton
                                    style={{border: 'none', height: '25px'}}
                                    onClick={() => {
                                        navigate(`/cons/client_profile/${row.client_id}`)
                                    }}
                                >{row.client_name}</MyButton>
                            </div>
                        ))
                        : <h4>Пока ни один клиент не записался...</h4>
                    }
                </div>
            }
        </div>
    );
};

export default EnrolledClientsList;