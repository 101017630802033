import React from 'react';
import cl from './MyModal.module.css'

const MyModal = ({active, setActive, children}) => {
    return (
        <div className={active ? 'cl.modal cl.active' : cl.modal} onClick={() => setActive(false)}>
            <div className={cl.modal_content} onClick={e => e.stopPropagation()}>
                {children}
            </div>

        </div>
    );
};

export default MyModal;