import React from 'react';
import ReactPlayer from "react-player";
import file from '../static/video/Big_Buck_Bunny_360_10s_1MB.mp4'
import preview from '../static/video/preview.img'
import cl from './styles/StartVideo.module.css'

const StartVideo = () => {
    return (
        <div className={cl.video}>
            {/*<ReactPlayer*/}
            {/*    url={file}*/}
            {/*    playing*/}
            {/*    controls*/}
            {/*    light*/}
            {/*    playIcon={<img className={cl.icon} src={preview} alt=""/>}*/}
            {/*/>*/}
        </div>
    );
};

export default StartVideo;