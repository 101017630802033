import React, {useEffect, useState} from 'react';
import MySelect from "../UI/select/MySelect";
import MyButton from "../UI/button/MyButton";
import cl from '../pages/styles/AdminUserCard.module.css'
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import MyModal from "../UI/modal/MyModal";

const Verify = ({rkList, psychoList, clientID, clientData, allPsychoData, allRKData}) => {
    const [verifyData, setVerifyData] = useState({})
    const [modalIsActive, setModalIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')

    useEffect(() => {
        setVerifyData({
            id: clientID,
            rkName: rkList[0]?.name,
            psychoName: psychoList[0]?.name,
            rk: rkList[0]?.id,
            psycho: psychoList[0]?.id
        })
    }, [clientData, rkList, psychoList]);

    const [fetchVerification, verificationIsLoading, verificationError] = useFetching(
        async () => {
            const response = await AdminService.verification(verifyData)
            setBackendResponse(response)
            setModalIsActive(true)
            setTimeout(() => {window.location.reload()}, [1000])
        })

    const [fetchBlocked, blockedIsLoading, blockedError] = useFetching(
        async () => {
            const response = await AdminService.changeUserCard({
                id: clientID,
                role_id: clientData.role_id,
                is_active: false,
                is_verified: false,
                type_of_activity: clientData.type_of_activity
            })
            setBackendResponse(response)
            setModalIsActive(true)
            setTimeout(() => {window.location.reload()}, [1000])
        })

    return (
        <div className={cl.verify}>
            <h3>Верификация</h3>
            <div className={cl.verify_select}>
                <p>Равный консультант</p>
                <MySelect
                    options={rkList}
                    defaultValue='РК'
                    value={verifyData.rkName}
                    onChange={(event) => {
                        setVerifyData({...verifyData, rk: allRKData[event], rkName: event})
                    }}
                />
            </div>
            <div className={cl.verify_select}>
                <p>Психолог</p>
                <MySelect
                    options={psychoList}
                    defaultValue='Психолог'
                    value={verifyData.psychoName}
                    onChange={(event) => {
                        setVerifyData({...verifyData, psycho: allPsychoData[event], psychoName: event})
                    }}
                />
            </div>
            <div className={cl.verify_buttons}>
                <MyButton
                    onClick={() => {
                        fetchVerification()
                    }}
                >Подтвердить</MyButton>
                <MyButton
                    onClick={() => {
                        fetchBlocked()
                    }}
                >Заблокировать</MyButton>
            </div>
            <div className={modalIsActive ? cl.verify_modal : cl.invisible}>
                <MyModal
                    active={modalIsActive}
                    setActive={setModalIsActive}
                    children={backendResponse}
                />
            </div>
        </div>
    );
};

export default Verify;