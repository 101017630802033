import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegistrationPage";
import UserProfile from "./pages/UserProfile";
import ForgotPassword from "./pages/ForgotPassword";
import ClientGroupCard from "./pages/ClientGroupCard";
import ClientEntries from "./pages/ClientEntries";
import ClientsList from "./pages/ClientsList";
import AdminGroupCard from "./pages/AdminGroupCard";
import AdminGroups from "./pages/AdminGroups";
import AdminDirectionCard from "./pages/AdminDirectionCard";
import AdminGroupDirections from "./pages/AdminGroupDirections";
import AdminUsers from "./pages/AdminUsers";
import Client from "./pages/Client";
import AdminPanel from "./pages/AdminPanel";
import AdminUserCard from "./pages/AdminUserCard";
import StartPage from "./pages/StartPage";
import Questionnaire from "./pages/Questionnaire";
import UserActivation from "./pages/UserActivation";
import ClientStats from "./pages/ClientStats";
import Agreement from "./pages/Agreement";
import ConsMeetingCard from "./pages/ConsMeetingCard";
import ClientGroups from "./pages/ClientGroups";
import MeetingsRK from "./pages/MeetingsRK";
import MeetingsPsycho from "./pages/MeetingsPsycho";
import ClientMeetingCard from "./pages/ClientMeetingCard";
import StartVideo from "./pages/StartVideo";
import AdminStats from "./pages/AdminStats";
import ConsStats from "./pages/ConsStats";


export const unAuthRoutes = [
    {path: '/login', component: LoginPage},
    {path: '/registration', component: RegistrationPage},
    {path: '/forgot_password', component: ForgotPassword},
    {path: '/client/activation/:id', component: UserActivation},
    {path: '/agreement', component: Agreement},
    {path: '/', component: StartVideo},
    {path: '*', component: StartPage}
]

export const authRoutes = [
    {path: '/profile', component: UserProfile},
    {path: '/client/questionnaire', component: Questionnaire},
    {path: '/agreement', component: Agreement},
    {path: '/', component: StartVideo},
    {path: '*', component: StartPage}
]

export const unVerifiedClientRoutes = [
    {path: '/client/get_stats', component: ClientStats}
]

export const clientRoutes = [
    {path: '/client/entries', component: ClientEntries},
    {path: '/client/get_stats', component: ClientStats},
    {path: '/client/meetings_rk', component: MeetingsRK},
    {path: '/client/meetings_psychologist', component: MeetingsPsycho},
    {path: '/client/meeting/:id', component: ClientMeetingCard},
    {path: '/client/groups', component: ClientGroups},
    {path: '/client/group/:id', component: ClientGroupCard},
]

export const consRoutes = [
    {path: '/cons/get_clients', component: ClientsList},
    {path: '/cons/get_stats', component: ConsStats},
    {path: '/cons/client_profile/:id', component: Client},
    {path: '/cons/client_stats/:id', component: ClientStats},
    {path: '/cons/groups', component: AdminGroups},
    {path: '/cons/group/:id', component: AdminGroupCard},
    {path: '/cons/meeting/:id', component: ConsMeetingCard},
]

export const adminRoutes = [
    {path: '/admin/group_directions', component: AdminGroupDirections},
    {path: '/admin/group_direction/:id', component: AdminDirectionCard},
    {path: '/admin/panel', component: AdminPanel},
    {path: '/admin/stats', component: AdminStats},
    {path: '/admin/users', component: AdminUsers},
    {path: '/admin/user/:id', component: AdminUserCard},
]