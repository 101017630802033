import React, {useContext, useState} from 'react';
import cl from "../pages/styles/Questionaire.module.css";
import Radio from "../UI/input/Radio";
import MyButton from "../UI/button/MyButton";
import Loader from "../UI/loader/Loader";
import {useFetching} from "../hooks/useFetching";
import ClientService from "../services/ClientService";
import {Context} from "../index";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../http";

const Scales = ({scaleType, scaleData, activeNumber, setActiveNumber, scores, setScores, isResult, setIsResult}) => {
    const {store} = useContext(Context)
    const [backendResponse, setBackendResponse] = useState('')
    const [noAnswer, setNoAnswer] = useState(false)
    const [currentRangeValue, setCurrentRangeValue] = useState('1')
    const navigate = useNavigate()

    const setValue = (label, value) => {
        let template = {}
        template[label] = value
        setScores(Object.assign(scores, template))
    }
    let questionnaireData = {
        client_id: store.userID,
        name: scaleType,
        result: 0,
        result_description: ''
    }

    const [fetchTestResult, testResultIsLoading, testResultError] = useFetching(
        async () => {
            const response = await ClientService.addBeckScaleResult(questionnaireData)
            setBackendResponse(response)
        })

    const changeQData = () => {
        const result = sumScores(scores)
        const resultText = conclusion(result)
        questionnaireData = {...questionnaireData, result: result, result_description: resultText}
    }

    const sumScores = (scores) => {
        return Object.values(scores).reduce((a, b) => Number(a) + Number(b), 0)
    }

    const conclusion = (totalScore) => {
        if (scaleType === 'depression') {
            if (totalScore <= 9) {
                return 'Симптомы депрессии отсутствуют. ' +
                    'Ваше психоэмоциональное состояние в норме и не вызывает никаких опасений.'
            } else if (10 <= totalScore && totalScore <= 15) {
                return 'Признаки легкой депрессии (субдепрессии). ' +
                    'Пора задуматься о своем психическом здоровье и обратиться за консультацией к психологу.'
            } else if (16 <= totalScore && totalScore <= 19) {
                return 'Симптомы умеренной депрессии. ' +
                    'Депрессия на данном этапе создает определенные трудности для нормальной жизни и ' +
                    'опасна быстрым переходом на следующую стадию. Необходимо обратиться за помощью к психологу.'
            } else if (20 <= totalScore && totalScore <= 29) {
                return 'Признаки выраженной депрессии (средней тяжести). ' +
                    'Депрессия на этой стадии создает преграды для нормальной жизни и легко может перейти в ' +
                    'тяжелую депрессию. Такое состояние нельзя оставлять без внимания и медицинского контроля, ' +
                    'необходимо в обязательном порядке записаться на консультацию психолога.'
            } else if (totalScore >= 30) {
                return 'Симптомы тяжелой депрессии. ' +
                    'Это последняя, самая опасная стадия депрессии, с которой вряд ли получится справиться в одиночку.' +
                    ' Необходимо в срочном порядке обратиться за помощью к психологу.'
            }
        } else if (scaleType === 'anxiety') {
            if (totalScore <= 21) {
                return 'Ваш результат свидетельствует о незначительном уровне тревоги.'
            } else if (22 <= totalScore && totalScore <= 35) {
                return 'Ваш результат свидетельствует о среднем уровне тревоги.'
            } else if (totalScore >= 36) {
                return 'Ваш результат свидетельствует об очень высоком уровне тревоги.'
            }
        } else {
            return ''
        }
    }
    return (
        <div>
            <div className={isResult ? cl.invisible : cl.questions}>
                {scaleData.map((row) => (
                    <button
                        key={row.number}
                        className={row.number === activeNumber ? cl.btn : cl.btn2}
                    >
                        {row.number}
                    </button>
                ))}
            </div>
            {scaleData.map((row) => (
                <div
                    className={row.number === activeNumber ? cl.block : cl.invisible}
                    key={row.legend}
                    id={row.number}
                >
                    {row.type === 'range'
                        ? <div>
                            <h4>{row.legend}</h4>
                            <div className={cl.range}>
                                <input
                                    type="range"
                                    min="1"
                                    max="10"
                                    step="1"
                                    defaultValue='1'
                                    list='rangeList'
                                    onChange={(event) => {
                                        setCurrentRangeValue(event.target.value)
                                        if (scaleType === 'fond' && [1, 2].includes(activeNumber)) {
                                            setValue(row.number, 11 - event.target.value)
                                        } else {
                                            setValue(row.number, event.target.value)
                                        }
                                    }}
                                />
                                <datalist id="rangeList">
                                    <option value="1" label="1"/>
                                    <option value="2" label="2"/>
                                    <option value="3" label="3"/>
                                    <option value="4" label="4"/>
                                    <option value="5" label="5"/>
                                    <option value="6" label="6"/>
                                    <option value="7" label="7"/>
                                    <option value="8" label="8"/>
                                    <option value="9" label="9"/>
                                    <option value="10" label="10"/>
                                </datalist>
                                <span
                                    id='rangeValue'>{currentRangeValue}</span>
                            </div>
                        </div>

                        : <div className={cl.radio}>
                            <Radio
                                legend={row.legend}
                                number={row.number}
                                pointsData={row.data}
                                onChangeFunc={setValue}
                            />
                        </div>
                    }

                    <div>
                        <MyButton
                            onClick={(event) => {
                                setCurrentRangeValue('1')
                                if (!(activeNumber in scores) && scaleType === 'fond') {
                                    if ([1, 2].includes(activeNumber)) {
                                        setValue(row.number, 10)
                                    } else {
                                        setValue(row.number, 1)
                                    }
                                }
                                if (activeNumber in scores) {
                                    setActiveNumber(activeNumber + 1)
                                    setNoAnswer(false)
                                    if (activeNumber >= Object.keys(scaleData).length) {
                                        changeQData()
                                        fetchTestResult()
                                        setIsResult(true)
                                    }
                                } else
                                    setNoAnswer(true)
                            }}
                        >Далее</MyButton>
                        <div className={noAnswer ? cl.block : cl.invisible}>
                            Сперва выберите наиболее подходящий вариант ответа
                        </div>
                    </div>

                </div>))}

            {testResultIsLoading
                ? <Loader/>
                : <div className={isResult ? cl.block : cl.invisible}>
                    <p className={cl.p_style}>Спасибо, что прошли этот тест!</p>
                    <p>Результат учтён:</p>
                    <p>{backendResponse}</p>
                    <p>Динамику своего состояния вы можете увидеть в разделе
                        <a
                            style={{color: "blueviolet", textDecoration: "none"}}
                            href={`https://bf-ravnovesie.ru/client/get_stats`}> «Статистика»</a></p>
                    {/*// href={`http://localhost:3000/client/get_stats`}> «Статистика»</a></p>*/}
                    <div className={sumScores(scores) > 9 && store.isVerified ? cl.recomend : cl.invisible}>
                        <p>Рекомендуем ознакомиться с нашими психологическими группами</p>
                        <div>
                            <MyButton
                                onClick={() => {
                                    navigate('/client/entries')
                                }
                                }
                            >Найти группу</MyButton>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default Scales;