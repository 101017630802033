import React, {useContext, useEffect, useState} from 'react';
import cl from './styles/ClientStats.module.css'
import {useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import ConsultantService from "../services/ConsultantService";
import Loader from "../UI/loader/Loader";
import {formatDate} from "../UI/date/dateFormat";
import MySelect from "../UI/select/MySelect";
import {Context} from "../index";
import cfg from "../cfg";

const ClientStats = () => {
    const {store} = useContext(Context)
    const params = useParams()
    const now = new Date()
    const selectOptions = [
        {name: 'Всё время', value: 'all'},
        {name: 'Год', value: 'year'},
        {name: 'Месяц', value: 'month'},
        {name: 'Диапазон', value: 'range'},
    ]
    const [rangeDate, setRangeDate] = useState({
        start: '2024-01-01',
        end: `${now.getFullYear()}-${now.getMonth() + 1}-${now.getDate()}`
    })

    // Блок для статистики по опросам
    const [questType, setQuestType] = useState('depression')
    const [questIsActive, setQuestIsActive] = useState(true)
    const [scaleGraph, setScaleGraph] = useState()
    const [selectValue, setSelectValue] = useState('all')
    const [fetchScaleGraph, scaleGraphIsLoading, scaleGraphError] = useFetching(
        async () => {
            const response = await ConsultantService.getClientStatByQuestionnaire(
                store.userRoleID !== cfg.clientID ? params.id : store.userID,
                selectValue === 'range' ? rangeDate.start + '_' + rangeDate.end : selectValue,
                questType)
            setScaleGraph(response)
    })

    // Блок для статистики по посещениям
    const [entriesIsActive, setEntriesIsActive] = useState(false)
    const [entriesType, setEntriesType] = useState('groups')
    const [meetingsType, setMeetingsType] = useState('rk')
    const meetingOptions = [
        {name: 'Равное консультирование', value: 'rk'},
        {name: 'Психология', value: 'psycho'},
    ]
    const [fetchMeetingEntriesGraph, meetingEntriesGraphIsLoading, meetingEntriesGraphError] =
        useFetching(async () => {
            if (selectValue === 'range') {
                const response = await ConsultantService.getMeetingStatByClient(
                    store.userRoleID !== cfg.clientID ? params.id : store.userID,
                    rangeDate.start + '_' + rangeDate.end,
                        meetingsType
                    )
                setMeetingEntriesGraph(response.graph)
                setMeetingEntriesData(response.meetings)
            }
            else {
                const response = await ConsultantService.getMeetingStatByClient(
                    store.userRoleID !== cfg.clientID ? params.id : store.userID,
                    selectValue,
                    meetingsType
                    )
                setMeetingEntriesGraph(response.graph)
                setMeetingEntriesData(response.meetings)
            }
        })
    const [entriesGraph, setEntriesGraph] = useState()
    const [meetingEntriesGraph, setMeetingEntriesGraph] = useState()
    const [entriesData, setEntriesData] = useState([])
    const [meetingEntriesData, setMeetingEntriesData] = useState([])
    const [fetchEntriesGraph, entriesGraphIsLoading, entriesGraphError] = useFetching(
        async () => {
            if (selectValue === 'range') {
                const response = await ConsultantService.getClientStatByEntries(
                    store.userRoleID !== cfg.clientID ? params.id : store.userID,
                    rangeDate.start + '_' + rangeDate.end)
                setEntriesGraph(response.graph)
                setEntriesData(response.entries)
            }
            else {
                const response = await ConsultantService.getClientStatByEntries(
                    store.userRoleID !== cfg.clientID ? params.id : store.userID,
                    selectValue)
                setEntriesGraph(response.graph)
                setEntriesData(response.entries)
            }
        })

    useEffect(() => {
        if (entriesIsActive && entriesType === 'groups') {
            fetchEntriesGraph()
        }
        else if (entriesIsActive && entriesType === 'meetings') {
            fetchMeetingEntriesGraph()
        }
        else if (questIsActive) {
            fetchScaleGraph()
        }

    }, [questType, selectValue, rangeDate, entriesIsActive, questIsActive, entriesType, meetingsType]);

    return (
        <div className={cl.context}>
            <h2>Статистика по клиенту</h2>
            <div className={cl.btns}>
                <button
                    className={questIsActive ? cl.btn_active : cl.btn_inactive}
                    onClick={() => {
                        setEntriesIsActive(false)
                        setQuestIsActive(true)
                    }}
                >Опросы
                </button>
                <button
                    className={entriesIsActive ? cl.btn_active : cl.btn_inactive}
                    onClick={() => {
                        setQuestIsActive(false)
                        setEntriesIsActive(true)
                    }}
                >Посещения
                </button>
            </div>
            <div className={entriesIsActive ? cl.radio : cl.invisible}>
                <div>
                    <input
                        type="radio"
                        name='entryType'
                        id='groups'
                        defaultChecked={true}
                        onChange={(event) => {
                            setEntriesType(event.target.id)
                        }}
                    />
                    <label htmlFor="groups">Группы</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name='entryType'
                        id='meetings'
                        onChange={(event) => {
                            setEntriesType(event.target.id)
                        }}
                    />
                    <label htmlFor="meetings">Встречи</label>
                </div>
            </div>

            <div className={entriesIsActive && entriesType === 'meetings' ? cl.select : cl.invisible}>
                <MySelect
                    options={meetingOptions}
                    defaultValue='Тип встречи'
                    value={meetingsType}
                    onChange={setMeetingsType}
                />
            </div>

            <div className={questIsActive ? cl.radio : cl.invisible}>
                <div>
                    <input
                        type="radio"
                        name='quest'
                        id='depression'
                        defaultChecked={true}
                        onChange={(event) => {
                            setQuestType(event.target.id)
                        }}
                    />
                    <label htmlFor="depression">Шкала депрессии Бека</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name='quest'
                        id='anxiety'
                        onChange={(event) => {
                            setQuestType(event.target.id)
                        }}
                    />
                    <label htmlFor="anxiety">Шкала тревоги Бека</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name='quest'
                        id='fond'
                        onChange={(event) => {
                            setQuestType(event.target.id)
                        }}
                    />
                    <label htmlFor="fond">Анкета актуального состояния</label>
                </div>
            </div>
            <div className={cl.select}>
                <MySelect
                    options={selectOptions}
                    defaultValue={'Период'}
                    value={selectValue}
                    onChange={setSelectValue}
                />
            </div>
            <div className={selectValue === 'range' ? cl.range : cl.invisible}>
                <p>с</p>
                <input
                    type={"date"}
                    className={cl.input_date}
                    onChange={e => setRangeDate(
                        {...rangeDate, start: e.target.value})}
                />
                <p>по</p>
                <input
                    type={"date"}
                    className={cl.input_date}
                    onChange={e => setRangeDate(
                        {...rangeDate, end: e.target.value})}
                />
            </div>

            {scaleGraphIsLoading
                ? <div className={questIsActive ? cl.loader : cl.invisible}><Loader/></div>
                : <div
                    className={questIsActive ? cl : cl.invisible}>
                    <img className={cl.graph} src={scaleGraph} alt=''/>
                </div>
            }

            {entriesGraphIsLoading
                ? <div className={entriesIsActive ? cl.loader : cl.invisible}><Loader/></div>
                : entriesType === 'groups'
                    ? <div className={entriesIsActive && entriesType === 'groups' ? cl : cl.invisible}>
                        <img className={cl.graph} src={entriesGraph} alt=''/>
                        <div className={entriesIsActive ? cl : cl.invisible}>
                            <h3>Посещения клиента</h3>
                            {entriesData.length
                                ? (entriesData.map((entry) => (
                                    <div
                                        key={entry.group_id}>
                                        <a
                                            className={cl.entries}
                                            href={
                                                `/${store.userRoleID === cfg.clientID ? 'client' : 'cons'}/group/${entry.group_id}`}
                                        >{formatDate(entry.group_date)} {entry.group_name}
                                        </a>
                                    </div>
                                )))
                                : <p>Посещения клиента не найдены</p>
                            }
                        </div>
                    </div>
                    : <div className={entriesIsActive && entriesType === 'meetings' ? cl : cl.invisible}>
                        <img className={cl.graph} src={meetingEntriesGraph} alt=''/>
                        <div className={entriesIsActive ? cl : cl.invisible}>
                            <h3>Посещения клиента</h3>
                            {meetingEntriesData.length
                                ? (meetingEntriesData.map((entry) => (
                                    <div
                                        key={entry.meeting_id}>
                                        <a
                                            className={cl.entries}
                                            href={
                                                `/${store.userRoleID === cfg.clientID ? 'client' : 'cons'}/meeting/${entry.meeting_id}`}
                                        >{formatDate(entry.meeting_start_date)} {entry.meeting_name}
                                        </a>
                                    </div>
                                )))
                                : <p>Посещения клиента не найдены</p>
                            }
                        </div>
                    </div>


            }
        </div>
    );
};

export default ClientStats;