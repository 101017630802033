import React, {useEffect, useState} from 'react';
import cl from './styles/AdminStats.module.css'
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import MySelect from "../UI/select/MySelect";
import AdminDirStat from "../components/AdminDirStat";
import AdminMeetingStatByCons from "../components/AdminMeetingStatByCons";
const AdminStats = () => {
    // Общий блок
    const [typeOfStat, setTypeOfStat] = useState('directions')
    //
    // Блок для получения статистики по направлениям
    const [directions, setDirections] = useState({})
    const [directionID, setDirectionID] = useState('')
    const [getDirections, directionsIsLoading, directionsError] = useFetching(async () => {
        const response = await AdminService.getDirections()
        setDirections({...response})
    })
    const [dirStat, setDirStat] = useState({})
    const [groupDirectionInfo, groupDirectionInfoIsLoading, groupDirectionInfoError] = useFetching(
        async () => {
            const statResponse = await AdminService.getDirectionStatistic(directionID, 'all', 'all')
            setDirStat(statResponse)
        })

    useEffect(() => {
        if (typeOfStat === 'directions') {
            getDirections()

        }
    }, [typeOfStat]);

    useEffect(() => {
        if (directionID.length) {
            groupDirectionInfo()

        }
    }, [directionID]);

    const getDirOptions = () => {
        let dirOptions = [{name: 'Выберите направление', value: ''}]
        if (Object.keys(directions).length) {
            for (let key in directions) {
                dirOptions.push(
                    {name: key, value: directions[key].dir_id})}
        }
        return dirOptions;
    }
    //
    // Блок для получения статистики по встречам
    const typeMeetingsData = [
        {name: 'Равное консультирование', value: 'rk'},
        {name: 'Психология', value: 'psycho'}
    ]
    const [typeMeetings, setTypeMeetings] = useState('rk')
    const [currentCons, setCurrentCons] = useState('')
    const [allRK, setAllRK] = useState({})
    const [allPsycho, setAllPsycho] = useState({})
    const [fetchGetCons, consIsLoading, consError] = useFetching(
        async () => {
            if (typeMeetings === 'rk') {
                const response = await AdminService.getRK()
                setAllRK(response)
            }
            else {
                const response = await AdminService.getPsycho()
                setAllPsycho(response)
            }
        })

    useEffect(() => {
        fetchGetCons()
    }, [typeMeetings]);

    const getCons = () => {
        let consOptions = [{name: 'Выберите сотрудника', value: ''}]
        let data = {}
        if (typeMeetings === 'rk') {
            data = allRK
        }
        else {
            data = allPsycho
        }

        if (Object.keys(data).length) {
            for (let key in data) {
                consOptions.push(
                    {name: key, value: data[key]})}
        }

        return consOptions;
    }

    return (
        <div className={cl.stats}>
            <h2>Тип статистики</h2>
            <div className={cl.stat_type}>
                <div>
                    <input type="radio"
                           name='type_stat_radio'
                           value='directions'
                           defaultChecked={true}
                           id='directions'
                           onChange={(event) => {
                               setTypeOfStat(event.target.value)
                           }}
                    />
                    <label htmlFor="directions">По направлениям</label>
                </div>
                <div>
                    <input
                        type="radio"
                        name='type_stat_radio'
                        value='meetings'
                        id='meetings'
                        onChange={(event) => {
                            setTypeOfStat(event.target.value)
                        }}
                    />
                    <label htmlFor="meetings">По встречам</label>
                </div>
                {/*<div>*/}
                {/*    <input*/}
                {/*        type="radio"*/}
                {/*        name='type_stat_radio'*/}
                {/*        value='clients'*/}
                {/*        id='clients'*/}
                {/*        onChange={(event) => {*/}
                {/*            setTypeOfStat(event.target.value)*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    <label htmlFor="clients">По клиентам</label>*/}
                {/*</div>*/}
            </div>

            <div className={typeOfStat === 'directions' ? cl.plug : cl.invisible}>
                <MySelect
                    defaultValue='Направление'
                    options={getDirOptions()}
                    onChange={setDirectionID}
                    value={directionID}
                />
                <div className={directionID.length ? cl.plug : cl.invisible}>
                    {directionID.length
                        ? <AdminDirStat
                            dir={directionID}
                            graph={dirStat.graph}
                        />
                        : void {}
                    }
                </div>
            </div>

            <div className={typeOfStat === 'meetings' ? cl.plug : cl.invisible}>
                <MySelect
                    defaultValue='Тип встреч'
                    options={typeMeetingsData}
                    onChange={setTypeMeetings}
                    value={typeMeetings}
                />
                <div>
                    <MySelect
                        defaultValue='Консультант'
                        options={getCons()}
                        onChange={setCurrentCons}
                        value={currentCons}
                        />
                </div>
                {currentCons.length
                    ? <AdminMeetingStatByCons
                        userID={currentCons}
                    />
                    : void {}

                }
            </div>
        </div>
    );
};

export default AdminStats;