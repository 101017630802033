import React, { useState } from 'react';
import MyInput from "../UI/input/MyInput";
import MyButton from "../UI/button/MyButton";
import MyModal from "../UI/modal/MyModal";
import cl from './styles/ForgotPassword.module.css'
import UserService from "../services/UserService";

const ForgotPassword = () => {
    const [emailForm, setEmailForm] = useState({
        email: ''
    })
    const [modalActive, setModalActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const onSubmitHandler = async (event) => {
        event.preventDefault()

        try {
            const response = await UserService.forgotPassword(emailForm)
            setBackendResponse(response.data)
        } catch (error) {
            setBackendResponse(error.response.data.detail)
        } finally {
            setModalActive(true)
        }
    }
    return (
        <div className={cl.content}>
            <form onSubmit={onSubmitHandler}>
                <div className={cl.body}>
                    <h2>Сброс пароля</h2>
                    <MyInput
                        style={{width: '300px'}}
                        placeholder='Введите свой email'
                        onChange={(event) => {
                            setEmailForm({ ...emailForm, email: event.target.value })
                        }} />

                    <MyModal
                        active={modalActive}
                        setActive={setModalActive}
                    >{backendResponse}
                    </MyModal>


                    <MyButton type='submit'>Выполнить</MyButton>
                </div>
            </form>
        </div>

    );
};

export default ForgotPassword;