import React from 'react';

const Radio = ({legend, number, pointsData, onChangeFunc, typeScale='depression'}) => {
    return (
        <div>
            <legend>{legend}</legend>

            {pointsData.map((row) => (
                <div key={row.desc}>
                    <input
                        type="radio"
                        id={row.value}
                        name={number}
                        value={row.value}
                        onChange={(event) => {
                            if (typeScale === 'fond') {
                                onChangeFunc(number, event.target.value, legend)
                            }
                            else {
                            onChangeFunc(number, Number(event.target.value), row.desc)
                            }}}
                    />
                    <label>{row.desc}</label>
                </div>))}
        </div>
    );
};

export default Radio;