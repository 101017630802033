import React from 'react';
import cl from './MySelect.module.css'

const MySelect = ({options, defaultValue, value, onChange}) => {
    return (
        <select
            id={defaultValue}
            className={cl.MySelect}
            value={value}
            onChange={(event) => {
                onChange(event.target.value)
            }}
        >
            <option className={cl.option} disabled={true}>{defaultValue}</option>

            {options.map(opt =>
                <option className={cl.option} key={opt.value} value={opt.value}>{opt.name}</option>
            )}
        </select>
    );
};

export default MySelect;