import React from 'react';
import cl from "../pages/styles/Client.module.css";
import Loader from "../UI/loader/Loader";
import MyButton from "../UI/button/MyButton";


const ClientCardByID = ({client, newNoteIsOpen, setNewNoteIsOpen, newNote, setNewNote, fetchNewNote}) => {
    return (
        <div>
            <div className={newNoteIsOpen ? cl.new__note : cl.none}>
                    <textarea
                        value={newNote}
                        placeholder='Заметку о клиенте будете видеть только Вы и куратор Фонда...'
                        onChange={(event) => {
                            setNewNote(event.target.value)
                        }}
                    />
                <div>
                    <MyButton
                        onClick={() => {
                            fetchNewNote()
                            setNewNoteIsOpen(false)
                            setNewNote('')
                        }}
                    >Добавить заметку</MyButton>
                    <MyButton
                        onClick={() => {
                            setNewNoteIsOpen(false)
                        }}
                    >Закрыть</MyButton>
                </div>
            </div>
            <h3>Карточка клиента</h3>
            <ul>
                <li>
                    <p>Имя:</p>
                    <h5>{client.name}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li>
                    <p>Фамилия:</p>
                    <h5>{client.surname}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li>
                    <p>Возраст:</p>
                    <h5>{client.age ? client.age : 'Не указан'}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li>
                    <p>Пол:</p>
                    <h5>{client.sex}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li>
                    <p>Город:</p>
                    <h5>{client.city}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li>
                    <p>Телефон:</p>
                    <h5>{client.phone}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li>
                    <p>Телеграм:</p>
                    <h5>{client.telegram_id}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li>
                    <p>Диагноз:</p>
                    <h5>{client.diagnosis}</h5>
                </li>
                <hr color='teal' size={1.5}/>
                <li style={{alignItems: 'flex-start'}}>
                    <p style={{marginTop: 5}}>О себе:</p>
                    <textarea
                        disabled
                        value={client.description}/>
                </li>
            </ul>
        </div>
    );
};

export default ClientCardByID;