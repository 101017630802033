import React, {useContext, useEffect, useState} from 'react';
import cl from './styles/AdminGroups.module.css'
import ConsGroups from "../components/ConsGroups";
import NewGroup from "../components/NewGroup";
import {useFetching} from "../hooks/useFetching";
import {Context} from "../index";
import AdminService from "../services/AdminService";
import cfg from '../cfg'
import ConsMeetings from "../components/ConsMeetings";

const AdminGroups = () => {

    const [newGroupIsActive, setNewGroupIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)
    const {store} = useContext(Context)
    const [directions, setDirections] = useState({})
    const [dirs, dirsIsLoading, dirsError] = useFetching(async () => {
        const response = await AdminService.getDirections()
        setDirections({...response})
    })

    const [groupLeaders, setGroupLeaders] = useState([])

    const [fetchGroupLeaders, groupLeadersIsLoading, groupLeadersError] = useFetching(
        async () => {
        const response = await AdminService.getAllGroupLeadersForSelect()
        setGroupLeaders(response)
    })

    useEffect(() => {
        dirs()
        fetchGroupLeaders()
    }, []);

    const getDirOptions = () => {
        let dirOptions = []
        if (Object.keys(directions).length) {
            for (let key in directions) {
                dirOptions.push({name: key, value: key, entries_limit: directions[key].entries_limit})
            }
        }
        return dirOptions;
    }

    const getLeaders = () => {

        let leadersOptions = []
        if (Object.keys(groupLeaders).length) {
            for (let key in groupLeaders) {
                leadersOptions.push({name: key, value: key, id: groupLeaders[key]})
            }
        }
        return leadersOptions;
    }


    return (
        <div className={cl.content}>
            <div className={cl.welcome}>
                <h4>Добрый день!
                    Добро пожаловать на платформу БФ «Равновесие».
                    В меню вы найдёте все необходимые разделы для работы.
                    Если у вас появятся вопросы, напишите нам в <a
                        style={{color: "teal"}}
                        target="_blank"
                        rel="noreferrer"
                        href="https://t.me/eq_tg_bot"> telegram</a>
                </h4>
            </div>

            <div className={store.userRoleID === cfg.adminID ? cl.plug : cl.cons_group_invisible}>
                <NewGroup
                    backendResponse={backendResponse}
                    setBackendResponse={setBackendResponse}
                    modalIsActive={modalIsActive}
                    setModalIsActive={setModalIsActive}
                    newGroupIsActive={newGroupIsActive}
                    setNewGroupIsActive={setNewGroupIsActive}
                    dirs={directions}
                    directionsIsLoading={dirsIsLoading}
                    dirOptions={getDirOptions()}
                    leadersOptions={getLeaders()}
                    groupLeadersIsLoading={groupLeadersIsLoading}
                    groupLeaders={groupLeaders}
                />
            </div>

            <div className={
                store.userRoleID === cfg.adminID ||
                (store.userRoleID === cfg.consultantID && store.typeOfActivity === 'group_leader')
                    ? cl.plug
                    : cl.cons_group_invisible
            }>
                <ConsGroups
                    setResponse={setBackendResponse}
                    newGroupIsActive={newGroupIsActive}
                    setNewGroupIsActive={setNewGroupIsActive}
                />
            </div>

            <div className={
                store.userRoleID === cfg.consultantID && store.typeOfActivity !== 'group_leader'
                    ? cl.plug
                    : cl.cons_group_invisible
            }>
                <ConsMeetings/>
            </div>

        </div>
    );
};

export default AdminGroups;