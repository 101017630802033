import React from 'react';
import ClientMeetings from "../components/ClientMeetings";


const MeetingsRK = () => {

    return (
        <div>
            <ClientMeetings
                typeOfMeeting='rk'
            />
        </div>
    );
};

export default MeetingsRK;