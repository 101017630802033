import React, {useContext} from 'react';
import {formatDate} from "../UI/date/dateFormat";
import cl from '../pages/styles/AdminGroupCard.module.css'
import Loader from "../UI/loader/Loader";
import {Context} from "../index";
import cfg from '../cfg'

const GroupCardById = ({group, isLoading, error, freeSeats}) => {
    const {store} = useContext(Context)
    return (
        <div>
            {isLoading
                ? <div className={cl.loader}><Loader/></div>
                : <div className={cl.group_card}>
                    <div className={cl.no_client}>
                        <div>
                            <p>Название</p>
                            <h4>{group.name}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Дата проведения</p>
                            <h4>{formatDate(group.group_date)}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Ведущий</p>
                            <h4>{group.cons_name}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Описание</p>
                            <h4>{group.description ? group.description : '-'}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Осталось свободных мест</p>
                            <h4>{freeSeats}</h4>
                        </div>
                    </div>
                    <div className={store.userRoleID === cfg.clientID ? cl.hidden : cl.no_client}>

                        <hr/>
                        <div>
                        <p>Тип группы</p>
                            <h4>{group.is_individual ? `Индивидуальная` : `Общая`}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Клиент может самостоятельно записаться на группу</p>
                            <h4>{group.self_sign_up ? `Да` : `Нет`}</h4>
                        </div>
                    </div>

                </div>
            }
        </div>
    );
};

export default GroupCardById;