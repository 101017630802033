import {type} from "@testing-library/user-event/dist/type";

const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

export const formatDate = (dt) => {
    const data = new Date(dt)
    return data.toLocaleString('ru', options)
}

