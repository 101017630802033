import React, {useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import ConsultantService from "../services/ConsultantService";
import MySelect from "../UI/select/MySelect";
import cl from "../pages/styles/AdminUserCard.module.css";
import Loader from "../UI/loader/Loader";
import {formatDate} from "../UI/date/dateFormat";
import MyButton from "../UI/button/MyButton";
import {useNavigate} from "react-router-dom";

const LeaderGroupStats = ({leaderID}) => {
    const [allOrUnique, setAllOrUnique] = useState('all')
    const [selectValue, setSelectValue] = useState('all')
    const navigate = useNavigate()
    const [rangeDate, setRangeDate] = useState({
        start: '1970-01-01',
        end: '2222-12-31'
    })
    const selectOptions = [
        {name: 'Всё время', value: 'all'},
        {name: 'Год', value: 'year'},
        {name: 'Месяц', value: 'month'},
        {name: 'Диапазон', value: 'range'},
    ]

    const [statGraph, setStatGraph] = useState('')
    const [groupsList, setGroupsList] = useState([])
    const [allStat, setAllStat] = useState({})
    const [fetchStat, statIsLoading, statError] = useFetching(async () => {
        const response = await ConsultantService.getGroupsStatByLeader(leaderID, selectValue === 'range'
                ? rangeDate.start + '_' + rangeDate.end
                : selectValue
            ,allOrUnique
        )
        setStatGraph(response.graph)
        setGroupsList(response.groups)
        setAllStat(response.all_stat)
    })

    useEffect(() => {
        fetchStat()
    }, [selectValue, rangeDate, leaderID, allOrUnique]);
    return (
        <div>
            <h3>Статистика по группам:</h3>
            <div>
                <MySelect
                    options={selectOptions}
                    defaultValue='Период'
                    value={selectValue}
                    onChange={setSelectValue}
                />
                <div className={selectValue === 'range' ? cl.range : cl.invisible}>
                    <p>с</p>
                    <input
                        type={"date"}
                        className={cl.input_date}
                        onChange={e =>
                            setRangeDate({...rangeDate, start: e.target.value})}
                    />
                    <p>по</p>
                    <input
                        type={"date"}
                        className={cl.input_date}
                        onChange={e =>
                            setRangeDate({...rangeDate, end: e.target.value})}
                    />
                </div>
                <div className={cl.unique_radio}>
                    <div>
                        <input type="radio"
                               name='unique_radio'
                               value='all'
                               defaultChecked={true}
                               id='all'
                               onChange={(event) => {
                                   setAllOrUnique(event.target.value)
                               }}
                        />
                        <label htmlFor="all">Все записи</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            name='unique_radio'
                            value='unique'
                            id='unique'
                            onChange={(event) => {
                                setAllOrUnique(event.target.value)
                            }}
                        />
                        <label htmlFor="unique">Уникальные записи</label>
                    </div>
                </div>
                {statIsLoading
                    ? <Loader/>
                    : <img className={groupsList.length ? cl.graph : cl.invisible} src={statGraph} alt=''/>
                }
                <div className={groupsList.length ? cl.plug : cl.invisible}>
                    <h3>Общая статистика:</h3>
                    <p>Количество групп: {allStat.meetings_count}</p>
                    <p>Количество записей: {allStat.entries_count}</p>
                    <p>Записей подтверждено: {allStat.presence_entries_count}</p>
                </div>
                <h3>Группы:</h3>
                {groupsList.length
                    ?
                    groupsList.map((group) => (
                        <div
                            className={cl.group_list}
                            key={group.group_id}
                        >
                            <p>
                                {formatDate(group.group_date)}
                            </p>
                            <MyButton
                                style={{border: 'none', height: '40px'}}
                                onClick={() => {
                                    navigate(`/cons/group/${group.group_id}`)
                                }}
                            >{group.group_name},
                                записано: {group.entries_count},
                                подтверждено: {group.presence_entries_count}</MyButton>

                            <hr className={cl.hr}/>
                        </div>
                    ))
                    :
                    <h4>Группы не найдены</h4>
                }
            </div>
        </div>
    );
};

export default LeaderGroupStats;