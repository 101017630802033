import React, {useContext, useEffect, useState} from 'react';
import GroupCardByID from "../components/GroupCardByID";
import cl from './styles/AdminGroupCard.module.css'
import MyButton from "../UI/button/MyButton";
import EntryClientOnGroup from "../components/EntryClientOnGroup";
import {useParams} from "react-router-dom";
import EnrolledClientsList from "../components/EnrolledClientsList";
import {useFetching} from "../hooks/useFetching";
import Loader from "../UI/loader/Loader";
import ConfirmEntry from "../components/ConfirmEntry";
import {Context} from "../index";
import AdminService from "../services/AdminService";
import cfg from '../cfg'
import MyModal from "../UI/modal/MyModal";

const AdminGroupCard = () => {
    const [group, setGroup] = useState({})
    const [entries, setEntries] = useState([])
    const [confirmEntriesIsActive, setConfirmEntriesIsActive] = useState(false)
    const [entryClient, setEntryClient] = useState(false)
    const params = useParams()
    const groupID = params.id
    const {store} = useContext(Context)

    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)
    const [freeSeatsCount, setFreeSeatsCount] = useState(0)

    const [fetchGroup, isLoading, error] = useFetching(async () => {
        const response = await AdminService.getGroupCard(groupID)
        setGroup(response)
    })

    const [fetchFreeSeats, freeSeatsIsLoading, freeSeatsError] = useFetching(
        async () => {
            setBackendResponse('')
            setModalIsActive(false)
            const response = await AdminService.getFreeSeatsOnGroup(groupID)
            setFreeSeatsCount(response)
    })

    const [enrolledClients, enrolledClientsIsLoading, enrolledClientsError] = useFetching(
        async () => {
        const response = await AdminService.getEnrolledClients(groupID)
        setEntries(response)
    })

    const [repeatGroupDate, setRepeatGroupDate] = useState('')
    const [repeatGroupIsActive, setRepeatGroupIsActive] = useState(false)

    useEffect(() => {
        fetchGroup()
        fetchFreeSeats()
        enrolledClients()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [fetchRepeatGroup, repeatGroupIsLoading, errorRepeatGroup] =
        useFetching(async () => {
            setBackendResponse('')
            if (!repeatGroupDate) {
                setBackendResponse('Укажите дату проведения группы')
            }
            else {
                const response = await AdminService.addNewGroup({
                    ...group, group_date: repeatGroupDate
                })
                setBackendResponse(response)
            }
            setModalIsActive(true)
        })

    const [fetchActivateGroup, activateGroupIsLoading, errorActivateGroup] =
        useFetching(async () => {
            setBackendResponse('')
            setModalIsActive(false)
            const response = await AdminService.changeGroupStatus({
                id: groupID,
                is_active: true
            })
            if (response.status === 200) {
                setGroup({...group, is_active: true})
            }
            setBackendResponse(response.data)
            setModalIsActive(true)
        })

    const [fetchDeactivateGroup, deactivateGroupIsLoading, errorDeactivateGroup] =
        useFetching(async () => {
            setBackendResponse('')
            setModalIsActive(false)
            const response = await AdminService.changeGroupStatus({
                id: groupID,
                is_active: false
            })
            if (response.status === 200) {
                setGroup({...group, is_active: false})
            }
            setBackendResponse(response.data)
            setModalIsActive(true)
        })

    useEffect(() => {
        setBackendResponse('')
        if (freeSeatsError.length) {
            setBackendResponse(freeSeatsError)
            setModalIsActive(true)
        }
        else if (enrolledClientsError.length) {
            setBackendResponse(enrolledClientsError)
            setModalIsActive(true)
        }
        else if (errorRepeatGroup.length) {
            setBackendResponse(errorRepeatGroup)
            setModalIsActive(true)
        }
        else if (errorActivateGroup.length) {
            setBackendResponse(errorActivateGroup)
            setModalIsActive(true)
        }
        else if (errorDeactivateGroup.length) {
            setBackendResponse(errorDeactivateGroup)
            setModalIsActive(true)
        }
    }, [freeSeatsError, enrolledClientsError, errorRepeatGroup, errorActivateGroup, errorDeactivateGroup]);

    return (
        <div>
            {isLoading
                ? <div className={cl.loader}>
                    <Loader/>
                  </div>
                : <div className={cl.content}>
                    <div className={
                        store.userRoleID === cfg.adminID || group.cons_id === store.userID
                            ? cl.btns
                            : cl.invisible
                    }>
                        <div>
                            <button
                                onClick={() => {
                                    setEntryClient(!entryClient)
                                    setModalIsActive(false)
                                    setRepeatGroupIsActive(false)
                                    setConfirmEntriesIsActive(false)
                                }}
                            >Записать клиентов</button>
                        </div>
                        <div>
                            <button
                                onClick={() => {
                                    setModalIsActive(false)
                                    setEntryClient(false)
                                    setRepeatGroupIsActive(false)
                                    if (Date.now() < Date.parse(group.group_date)) {
                                        setBackendResponse('Подтвердить присутствие можно только позднее ' +
                                            'даты проведения встречи')
                                        setModalIsActive(true)
                                    }
                                    else {
                                        setConfirmEntriesIsActive(true)
                                    }
                                }}
                            >Подтвердить присутствие</button>
                        </div>
                        <div className={store.userRoleID === cfg.adminID ? cl.plug : cl.invisible}>
                            <button
                                onClick={() => {
                                    setModalIsActive(false)
                                    setBackendResponse('')
                                    setRepeatGroupIsActive(!repeatGroupIsActive)
                                    setConfirmEntriesIsActive(false)
                                    setEntryClient(false)
                                }}
                            >Повторить</button>
                        </div>
                        <div className={
                            store.userRoleID === cfg.adminID
                                ? group.is_active
                                    ? cl.plug
                                    : cl.invisible
                                : cl.invisible
                            }>
                            <button
                                onClick={() => {
                                    if (entries.length) {
                                        setBackendResponse('Вы не можете деактивировать группу. ' +
                                            'На нее уже записаны клиенты')
                                        setModalIsActive(true)
                                    }
                                    else {
                                        fetchDeactivateGroup()
                                    }
                                }}
                            >Деактивировать</button>
                        </div>
                        <div className={
                            store.userRoleID === cfg.adminID
                                ? !group.is_active
                                    ? cl.plug
                                    : cl.invisible
                                : cl.invisible}>
                            <MyButton
                                onClick={() => {
                                    fetchActivateGroup()
                                }}
                            >Активировать</MyButton>
                        </div>
                    </div>
                    <GroupCardByID
                        group={group}
                        isLoading={isLoading}
                        error={error}
                        freeSeats={freeSeatsCount}
                    />

                    <div className={repeatGroupIsActive ? cl.repeat : cl.invisible}>
                        <h3>Создание новой группы на основе текущей</h3>
                        <p>Укажите дату:</p>
                        <input
                            onChange={(event) => {
                                setRepeatGroupDate(event.target.value)
                            }}
                            type="datetime-local"/>
                        <div>
                            <button
                                onClick={() => {
                                    fetchRepeatGroup()
                                }}
                            >Создать</button>
                            <button
                                onClick={() => {
                                    window.location.reload()
                                }}
                            >Закрыть</button>
                        </div>
                    </div>

                    {freeSeatsIsLoading || repeatGroupIsLoading || activateGroupIsLoading || deactivateGroupIsLoading
                        ? <div><Loader/></div>
                        : void {}
                    }

                    {modalIsActive
                        ? <div className={cl.modal}>
                            <MyModal
                                active={modalIsActive}
                                setActive={setModalIsActive}
                                children={backendResponse}
                            />
                            </div>
                        : void {}

                    }

                    <div className={confirmEntriesIsActive ? cl.invisible : cl.search_clients}>
                        <EnrolledClientsList
                            clients={entries}
                            clientsIsLoading={enrolledClientsIsLoading}
                        />
                    </div>

                    <div className={entryClient && !confirmEntriesIsActive ? cl.search_clients : cl.invisible}>
                        <h3>Запись и удаление клиентов</h3>
                        <EntryClientOnGroup
                            group={group}
                            setEntryClient={setEntryClient}
                        />
                    </div>

                    <div className={confirmEntriesIsActive ? cl.search_clients : cl.invisible}>
                        <ConfirmEntry
                            group={group}
                            entriesList={entries}
                            confirmIsActive={confirmEntriesIsActive}
                        />
                    </div>
                </div>
            }

        </div>
    );
};

export default AdminGroupCard;