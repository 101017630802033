import React, {useContext, useState} from 'react';
import cl from './styles/LoginPage.module.css'
import MyButton from '../UI/button/MyButton';
import MyInput from '../UI/input/MyInput';
import 'react-toastify/dist/ReactToastify.css'
import {Link, useNavigate} from 'react-router-dom';
import MyModal from "../UI/modal/MyModal";
import UserService from "../services/UserService";
import {Context} from "../index";
import {observer} from "mobx-react-lite";
import cfg from "../cfg";


const LoginPage = () => {
    const navigate = useNavigate();
    const [backendResponse, setBackendResponse] = useState('')
    const [modalActive, setModalActive] = useState(false)
    const [loginForm, setLoginForm] = useState({
        email: '',
        password: ''
    });
    const {store} = useContext(Context);
    const onChangeForm = (label, event) => {
        switch (label) {
            case "email":
                setLoginForm({ ...loginForm, email: event.target.value });
                break;
            case "password":
                setLoginForm({ ...loginForm, password: event.target.value });
                break;
        }
    };
    const onSubmitHandler = async (event) => {
        event.preventDefault();

        try {
            await store.login(loginForm)
            const responseProfile = await UserService.profile({params: {
                    email: loginForm.email
                }})
            setModalActive(false)
            if (responseProfile.data.role_id === cfg.adminID || responseProfile.data.role_id === cfg.consultantID) {
                navigate('/cons/groups')
            }
            else if (responseProfile.data.is_verified) {
                navigate('/client/entries')
            }
            else {
                navigate('/profile')
            }
        } catch (error) {
            setBackendResponse(error.response?.data?.detail)
            setModalActive(true)
        }
    };

    return (
        <React.Fragment>
            <div className={cl.body}>
                <div className={cl.wrapper}>
                    <form onSubmit={onSubmitHandler}>
                        <h1>Вход</h1>
                        <MyInput
                            type='text'
                            placeholder='Email'
                            onChange={(event) => {
                                onChangeForm('email', event)
                            }}
                        />
                        <MyInput
                            type='password'
                            placeholder='Пароль'
                            onChange={(event) => {
                                onChangeForm('password', event)
                            }}
                        />
                        <h1 style={{ fontSize: 16 }}>Ещё нет аккаунта?
                            <Link
                            className={cl.ahref}
                            to='https://bf-ravnovesie.ru/registration'
                            > Зарегистрироваться
                            </Link>
                        </h1>
                        <div className={cl.btn}>
                            <MyButton
                                type='submit'
                            >Войти</MyButton>
                        </div>
                        <div>
                            <MyModal
                                active={modalActive}
                                setActive={setModalActive}
                            >{backendResponse}
                            </MyModal>
                        </div>
                        <div>
                            <Link
                                style={{ color: 'teal' }}
                                className={cl.ahref}
                                to='https://bf-ravnovesie.ru/forgot_password'
                                >Забыли пароль?
                            </Link>
                        </div>
                        
                    </form>
                </div>
            </div>
        </React.Fragment>

    );
};

export default observer(LoginPage);
