import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import AdminUserCardByID from "../components/AdminUserCardByID";
import cl from './styles/AdminUserCard.module.css'
import MyModal from "../UI/modal/MyModal";
import cfg from '../cfg'
import Verify from "../components/Verify";
import AdminMeetingStatByCons from "../components/AdminMeetingStatByCons";

const AdminUserCard = () => {
    const navigate = useNavigate()
    const [modalIsActive, setModalIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const params = useParams()
    const userID = params.id
    const [user, setUser] = useState({})
    const [verificationIsActive, setVerificationIsActive] = useState(false)
    // const [verificationModalIsActive, setVerificationModalIsActive] = useState(false)
    const [allRK, setAllRK] = useState({})
    const [allPsycho, setAllPsycho] = useState({})
    const [changeUserData, setChangeUserData] = useState({})
    const [fetchUser, userIsLoading, userError] = useFetching(
        async () => {
        const response = await AdminService.getUserProfile(userID)
        setUser(response)
        setChangeUserData(response)
    })

    const [fetchRK, rkIsLoading, rkError] = useFetching(async () => {
        const response = await AdminService.getRK()
        setAllRK(response)
    })

    const [fetchPsycho, psychoIsLoading, psychoError] = useFetching(async () => {
        const response = await AdminService.getPsycho()
        setAllPsycho(response)
    })

    useEffect(() => {
        fetchUser()
    }, []);

    useEffect(() => {
        if (!user.is_verified) {
            fetchRK()
            fetchPsycho()
        }
    }, [user]);

    const [fetchChangeCard, changeCardIsLoading, changeCardError] = useFetching(
        async () => {
        const response = await AdminService.changeUserCard({
            id: userID,
            role_id: changeUserData.role_id,
            is_active: changeUserData.is_active,
            is_verified: changeUserData.is_verified,
            type_of_activity: changeUserData.type_of_activity
        })
        setBackendResponse(response)
        setModalIsActive(true)
            setTimeout(() => {window.location.reload()}, [1000])
    })

    useEffect(() => {
        if (userError.length) {
            setBackendResponse(userError)
            setModalIsActive(true)
        }
        else if (changeCardError.length) {
            setBackendResponse(changeCardError)
            setModalIsActive(true)
        }
        else if (psychoError.length) {
            setBackendResponse(psychoError)
            setModalIsActive(true)
        }
        else if (rkError.length) {
            setBackendResponse(rkError)
            setModalIsActive(true)
        }
    }, [userError, changeCardError, psychoError, rkError]);

    const [meetingsListIsActive, setMeetingsListIsActive] = useState(false)

    const getPsycho = () => {
        let psychoOptions = []
        if (Object.keys(allPsycho).length) {
            for (let key in allPsycho) {
                psychoOptions.push({name: key, value: key, id: allPsycho[key]})
            }
        }
        return psychoOptions;
    }

    const getRK = () => {

        let rkOptions = []
        if (Object.keys(allRK).length) {
            for (let key in allRK) {
                rkOptions.push({name: key, value: key, id: allRK[key]})
            }
        }
        return rkOptions;
    }


    return (
        <div className={cl.content}>
            <div className={cl.buttons}>
                <button
                    onClick={() => {
                        fetchChangeCard()
                    }}
                >Редактировать
                </button>

                <button
                    className={
                    user.role_id === cfg.consultantID && user.type_of_activity !== 'group_leader'
                        ? cl.button_plug
                        : cl.invisible
                }
                    onClick={() => {
                        setMeetingsListIsActive(!meetingsListIsActive)
                    }}
                >Встречи
                </button>

                <button
                    className={!user.is_verified && user.is_active ? cl.button_plug : cl.invisible}
                    onClick={() => {
                        setVerificationIsActive(!verificationIsActive)
                    }}
                >Верифицировать
                </button>

                <button
                    onClick={() => {
                        navigate(-1)
                    }}
                >Назад
                </button>
            </div>
            <AdminUserCardByID
                user={changeUserData}
                setUser={setChangeUserData}
                isLoading={userIsLoading}
            />
            <div
                className={cl.modal}>
                <MyModal
                    active={modalIsActive}
                    setActive={setModalIsActive}
                    children={backendResponse}
                />
            </div>
            <div
                className={meetingsListIsActive ? cl.meetings_stat : cl.invisible}>
                <AdminMeetingStatByCons
                    userID={userID}
                />
            </div>
            <div className={verificationIsActive ? cl.plug : cl.invisible}>
                <Verify
                    rkList={getRK()}
                    psychoList={getPsycho()}
                    clientID={userID}
                    clientData={user}
                    allPsychoData={allPsycho}
                    allRKData={allRK}
                />
            </div>
        </div>
    );
};

export default AdminUserCard;