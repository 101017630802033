import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import ClientService from "../services/ClientService";
import cl from "./styles/ClientGroups.module.css";
import MyButton from "../UI/button/MyButton";
import Loader from "../UI/loader/Loader";
import {formatDate} from "../UI/date/dateFormat";

const ClientGroups = () => {

    const navigate = useNavigate()
    const [freeGroupsIsActive, setFreeGroupsIsActive] = useState(false)
    const [freeGroups, setFreeGroups] = useState([])
    const [clientGroupsIsActive, setClientGroupsIsActive] = useState(false)
    const [clientGroups, setClientGroups] = useState([])

    const [groupsList, groupsIsLoading, groupsError] = useFetching(
        async () => {
            const response = await ClientService.getFreeGroups()
            setFreeGroups(response)
        })

    const [fetchClientGroups, clientGroupsIsLoading, clientGroupsError] = useFetching(
        async () => {
            const response = await ClientService.getClientGroup()
            setClientGroups(response)
        })

    return (
        <div className={cl.content}>
            <div className={cl.buttons}>
                <MyButton
                    onClick={() => {
                        setClientGroupsIsActive(false)
                        groupsList()
                        setFreeGroupsIsActive(!freeGroupsIsActive)
                    }}
                >Поиск групп</MyButton>
                <MyButton
                    onClick={() => {
                        setFreeGroupsIsActive(false)
                        fetchClientGroups()
                        setClientGroupsIsActive(!clientGroupsIsActive)
                    }}
                >Мои группы</MyButton>
            </div>

            <h4 className={freeGroupsIsActive || clientGroupsIsActive ? cl.invisible : cl.groups}>
                * В этом разделе Вы можете посмотреть свои актуальные записи на группы или записаться на новые...
            </h4>

            <div className={freeGroupsIsActive ? cl.groups : cl.invisible}>
                {groupsIsLoading && !clientGroupsIsActive
                    ? <Loader/>
                    : <div>
                        <h3>Ближайшие группы</h3>
                        {freeGroups.length
                            ? freeGroups.map((group) => (
                                <div key={group.id}>
                                    <p>{formatDate(group.group_date)}</p>
                                    <MyButton
                                        style={{border: 'none', height: '25px'}}
                                        onClick={() => {
                                            navigate(`/client/group/${group.id}`)
                                        }}
                                    >{group.name}</MyButton>
                                </div>
                            ))
                            : <h4>Группы не найдены</h4>
                        }
                    </div>

                }
            </div>

            <div className={clientGroupsIsActive ? cl.groups : cl.invisible}>
                {clientGroupsIsLoading
                    ? <div><Loader/></div>
                    : <div>
                        <h3>Ваши группы</h3>
                        <div>
                            {clientGroups.length
                                ? clientGroups.map((group) => (
                                    <div
                                        key={group.id}>
                                        <p>{formatDate(group.group_date)}</p>
                                        <MyButton
                                            style={{border: 'none'}}
                                            onClick={() => {
                                                navigate(`/client/group/${group.id}`)
                                            }}
                                        >{group.name}</MyButton>
                                    </div>
                                ))
                                : <h4>Похоже Вы еще не записаны ни на одну группу</h4>
                            }
                        </div>
                    </div>
                }

            </div>

        </div>
    );
};

export default ClientGroups;