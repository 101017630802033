import React, { useState } from 'react';
import cl from './styles/RegistrationPage.module.css'
import MyButton from '../UI/button/MyButton';
import MyInput from '../UI/input/MyInput';
import { useNavigate } from 'react-router';
import MyModal from "../UI/modal/MyModal";
import InternationalPhone from "../UI/phoneValidation/InternationalPhone";
import AuthService from "../services/AuthService";

const RegistrationPage = () => {

    const navigate = useNavigate();
    const [backendResponse, setBackendResponse] = useState('')
    const [modalActive, setModalActive] = useState(false)
    const [formRegister, setFormRegister] = useState({
        name: '',
        surname: '',
        age: 0,
        telegram_id: '',
        diagnosis: '',
        email: '',
        phone: '',
        password: '',
        repeat_password: ''
    });

    const setPhone = (phone) => {
        setFormRegister({ ...formRegister, phone: phone });
    }

    const onChangeForm = (label, event) => {
        // eslint-disable-next-line default-case
        switch (label) {
            case 'name':
                setFormRegister({ ...formRegister, name: event.target.value });
                break;
            case 'surname':
                setFormRegister({ ...formRegister, surname: event.target.value });
                break;
            case 'age':
                setFormRegister({ ...formRegister, age: event.target.value });
                break;
            case 'telegram_id':
                setFormRegister({ ...formRegister, telegram_id: event.target.value });
                break;
            case 'diagnosis':
                setFormRegister({ ...formRegister, diagnosis: event.target.value });
                break;
            case 'email':
                setFormRegister({ ...formRegister, email: event.target.value });
                break;
            case 'password':
                setFormRegister({ ...formRegister, password: event.target.value });
                break;
            case 'repeat_password':
                setFormRegister({ ...formRegister, repeat_password: event.target.value });
                break;
        }
    };
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        try {
            setModalActive(false)
            const response = await AuthService.registration(formRegister)
            setBackendResponse(response)
            setModalActive(true)
            setTimeout(() => {
                navigate('/login');
            }, 5000);
        } catch (error) {
            setBackendResponse(error?.response?.data?.detail);
            setModalActive(true)
        }
    };

    return (
        <div className={cl.body}>
        <div className={cl.wrapper}>
            <form onSubmit={onSubmitHandler}>
                <ul>
                    <div className={cl.content}>

                        <h1>Регистрация</h1>
                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('name', event)
                                }}
                                type='text'
                                placeholder='Имя'
                            />
                        </li>

                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('surname', event)
                                }}
                                type='text'
                                placeholder='Фамилия'
                            />
                        </li>

                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('age', event)
                                }}
                                type='number'
                                placeholder='Возраст'
                            />
                        </li>

                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('diagnosis', event)
                                }}
                                type='text'
                                placeholder='Диагноз'
                            />
                        </li>

                        <li>
                            <div className={cl.phone}>
                                <InternationalPhone change={setPhone} value=''/>
                            </div>
                        </li>

                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('telegram_id', event)
                                }}
                                type='text'
                                placeholder='Telegram ID'
                            />
                        </li>

                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('email', event)
                                }}
                                type='text'
                                placeholder='Email'
                            />
                        </li>

                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('password', event)
                                }}
                                type='password'
                                placeholder='Пароль'
                            />
                        </li>

                        <li>
                            <MyInput
                                onChange={(event) => {
                                    onChangeForm('repeat_password', event)
                                }}
                                type='password'
                                placeholder='Повторите пароль'
                            />
                        </li>

                        <div className={cl.my_modal}>
                            <MyModal
                                active={modalActive}
                                setActive={setModalActive}
                            >{backendResponse}
                            </MyModal>
                        </div>
                        <div className={cl.btn}>
                            <MyButton type='submit'>Создать аккаунт</MyButton>
                        </div>
                    </div>
                </ul>
            </form>
        </div>
        </div>
    );
}

export default RegistrationPage;
