import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import ClientService from "../services/ClientService";
import cl from "./styles/ClientEntries.module.css";
import {formatDate} from "../UI/date/dateFormat";
import ConsultantService from "../services/ConsultantService";
import {Context} from "../index";
import MyModal from "../UI/modal/MyModal";
import Loader from "../UI/loader/Loader";

const ClientMeetingCard = () => {
    const params = useParams()
    const {store} = useContext(Context)
    const navigate = useNavigate()
    const now = new Date()
    const [modalIsActive, setModalIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const [meetingData, setMeetingData] = useState({})
    const [fetchMeetingInfo, meetingInfoIsLoading, meetingInfoError] = useFetching(
        async () => {
            const response = await ClientService.getMeetingInfo(params.id)
            setMeetingData(response)
        })

    const [fetchAddEntry, addEntryIsLoading, addEntryError] = useFetching(
        async () => {
            setModalIsActive(false)
            const response = await ConsultantService.addNewEntry({
                meeting_id: meetingData.id,
                meeting_name: meetingData.name,
                cons_name: meetingData.cons_name,
                is_presence: false,
                meeting_start_date: meetingData.meeting_start_date,
                meeting_end_date: meetingData.meeting_end_date,
                client_id: store.userID,
                cons_id: meetingData.cons_id
            })
            setBackendResponse(response)
            setModalIsActive(true)
        })

    const [fetchDeleteEntry, deleteEntryIsLoading, deleteEntryError] = useFetching(
        async () => {
            setModalIsActive(false)
            const response = await ConsultantService.deleteMeetingEntry({
                meeting_id: meetingData.id,
                client_id: store.userID,
            })
            setBackendResponse(response)
            setModalIsActive(true)
        })

    useEffect(() => {
        fetchMeetingInfo()
    }, []);

    useEffect(() => {
        if ((meetingInfoError + addEntryError + deleteEntryError).length) {
            setBackendResponse(meetingInfoError + addEntryError + deleteEntryError)
            setModalIsActive(true)
        }
    }, [meetingInfoError, addEntryError, deleteEntryError]);

    return (
        <div className={cl.meetings}>
            <div className={new Date(meetingData.meeting_start_date) > now ? cl.meetings_buttons : cl.invisible}>
                <button
                    onClick={() => {
                        fetchAddEntry()
                    }}
                >Записаться
                </button>
                <button
                    onClick={() => {
                        fetchDeleteEntry()
                    }}
                >Отменить
                </button>
                <button
                    onClick={() => {navigate(-1)}}
                >Назад</button>
            </div>
            {meetingInfoIsLoading
                ? <div className={cl.loader}><Loader/></div>
                : <div className={cl.cons_info}>
                    <div>
                        <p>Название:</p>
                        <h5>{meetingData.name}</h5>
                    </div>
                    <hr/>
                    <div>
                        <p>Дата начала:</p>
                        <h5>{formatDate(meetingData.meeting_start_date)}</h5>
                    </div>
                    <hr/>
                    <div>
                        <p>Дата окончания:</p>
                        <h5>{formatDate(meetingData.meeting_end_date)}</h5>
                    </div>
                    <hr/>
                    <div>
                        <p>{meetingData.type_of_meeting === 'rk' ? 'Консультант:' : 'Психолог:'}</p>
                        <h5>{meetingData.cons_name}</h5>
                    </div>
                    <hr/>
                    <div>
                        <p>Описание:</p>
                        <h5>{meetingData.description}</h5>
                    </div>
                </div>
            }

            {addEntryIsLoading || deleteEntryIsLoading
                ? <div className={cl.loader}><Loader/></div>
                : <div className={modalIsActive ? cl.modal : cl.invisible}>
                    <MyModal
                        active={modalIsActive}
                        setActive={setModalIsActive}
                        children={backendResponse}
                    />
                </div>
            }
        </div>
    );
};

export default ClientMeetingCard;