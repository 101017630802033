import React, {useEffect, useState} from 'react';
import cl from './styles/AdminDirectionCard.module.css'
import {useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import Loader from "../UI/loader/Loader";
import {formatDate} from "../UI/date/dateFormat";
import MyButton from "../UI/button/MyButton";
import MyInput from "../UI/input/MyInput";
import MyModal from "../UI/modal/MyModal";
import AdminDirStat from "../components/AdminDirStat";

const AdminDirectionCard = () => {

    const [modalIsActive, setModalIsActive] = useState(false)
    const [statIsActive, setStatIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const groupDirectionID = params.id
    const [direction, setDirection] = useState({})
    const [dirStat, setDirStat] = useState({})
    const [groupDirectionInfo, groupDirectionInfoIsLoading, groupDirectionInfoError] = useFetching(
        async () => {
            const response = await AdminService.getOneDirection(groupDirectionID)
            setDirection(response)
            setChangeDirectionData({
                id: response.id,
                name: response.name,
                is_individual: response.is_individual,
                entries_limit: response.entries_limit
            })
            const statResponse = await AdminService.getDirectionStatistic(groupDirectionID, 'all', 'all')
            setDirStat(statResponse)
    })

    useEffect(() => {
        groupDirectionInfo()
    }, []);

    const [changeDirIsActive, setChangeDirIsActive] = useState(false)

    const [changeDirectionData, setChangeDirectionData] = useState({})

    const [fetchChangeDirection, changeDirectionIsLoading, changeDirectionError] = useFetching(
        async () => {
            const response = await AdminService.changeDirection({
                ...changeDirectionData, id: groupDirectionID
            })
            setBackendResponse(response)
            reload()
        })

    const reload = () => {
        setModalIsActive(true)
        setIsLoading(true)
        setTimeout(() => {
            document.location.reload()
        }, 1000)
    }

    const [fetchDeactivate, deactivateIsLoading, deactivateError] = useFetching(
        async () => {
        const response = await AdminService.deactivateDirection({
            id: groupDirectionID
        })
            setBackendResponse(response)
            reload()
    })

    const [fetchActivate, activateIsLoading, activateError] = useFetching(
        async () => {
        const response = await AdminService.activateDirection({
            id: groupDirectionID,
            name: direction.name,
            is_individual: direction.is_individual,
            entries_limit: direction.entries_limit
        })
            setBackendResponse(response)
            reload()
    })

    // const errors = useMemo(() => {
    //     setBackendResponse(groupDirectionInfoError + changeDirectionError + activateError + deactivateError)
    //     setModalIsActive(true)
    // }, [groupDirectionInfoError, changeDirectionError, activateError, deactivateError]);

    return (
        <div>
            <div className={cl.buttons}>
                <div className={cl.btn}>
                    <button
                        onClick={() => {
                            setChangeDirIsActive(!changeDirIsActive)
                        }}
                    >Редактировать</button>
                </div>
                <div className={direction.is_active ? cl.btn : cl.invisible}>
                    <button
                        onClick={() => {
                            fetchDeactivate()
                        }}
                    >Деактивировать</button>
                </div>
                <div className={direction.is_active ? cl.invisible : cl.btn}>
                    <button
                        onClick={() => {
                            fetchActivate()
                        }}
                    >Активировать</button>
                </div>
                <div className={cl.btn}>
                    <button
                        onClick={() => {
                            setStatIsActive(!statIsActive)
                        }}
                    >Статистика</button>
                </div>
            </div>

            <div className={cl.direction_card}>
                {groupDirectionInfoIsLoading || changeDirectionIsLoading || isLoading
                    ? <div className={cl.loader}><Loader/></div>
                    : <div>
                        <div>
                            <p>Название</p>
                            <h4>{direction.name}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Тип группы</p>
                            <h4>{direction.is_individual ? 'Индивидуальная' : 'Общая'}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Лимит участников</p>
                            <h4>{direction.entries_limit}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Активна</p>
                            <h4>{direction.is_active ? 'Да' : 'Нет'}</h4>
                        </div>
                        <hr/>
                        <div>
                            <p>Дата создания</p>
                            <h4>{formatDate(direction.create_date)}</h4>
                        </div>
                    </div>
                }
            </div>

            <div className={changeDirIsActive ? cl.change_dir : cl.invisible}>
                <MyInput
                    defaultValue={changeDirectionData.name}
                    placeholder='Название направления'
                    onChange={(event) => {
                        setChangeDirectionData({...changeDirectionData, name: event.target.value})
                    }}
                />
                <div className={cl.limit}>
                    <p>Лимит участников</p>
                    <input
                        className={cl.input_limit}
                        type="number"
                        defaultValue={changeDirectionData.entries_limit}
                        onChange={(event) => {
                            setChangeDirectionData({...changeDirectionData, entries_limit: event.target.value})
                        }}
                    />
                </div>
                <div>
                <MyButton
                        onClick={() => {
                            fetchChangeDirection()
                        }}
                    >Сохранить изменения</MyButton>
                </div>
            </div>
            <div>
                {backendResponse.length
                    ? <div className={cl.modal}>
                        <MyModal
                            active={modalIsActive}
                            setActive={setModalIsActive}
                            children={backendResponse}
                        />
                    </div>
                    : void {}


                }
            </div>
            <div className={statIsActive ? cl.graph : cl.invisible}>
                <AdminDirStat
                    graph={dirStat.graph}
                    dir={groupDirectionID}
                />
            </div>

        </div>
    );
};

export default AdminDirectionCard;