import React, {useContext} from 'react';
import {useParams} from "react-router-dom";
import {Context} from "../index";
import AdminMeetingStatByCons from "../components/AdminMeetingStatByCons";
import cl from './styles/AdminStats.module.css'
import cfg from "../cfg";
import LeaderGroupStats from "../components/LeaderGroupStats";

const ConsStats = () => {
    const {store} = useContext(Context)
    return (
        <div className={cl.stats}>
            <div className={
                store.userRoleID === cfg.consultantID && store.typeOfActivity !== 'group_leader'
                    ? cl.plug
                    : cl.invisible
            }>
                <AdminMeetingStatByCons
                    userID={store.userID}
                />
            </div>

            <div className={
                store.userRoleID === cfg.consultantID && store.typeOfActivity === 'group_leader'
                    ? cl.plug
                    : cl.invisible
            }>
                <LeaderGroupStats
                    leaderID={store.userID}
                />
            </div>

        </div>
    );
};

export default ConsStats;