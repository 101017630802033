import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useParams} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import ConsultantService from "../services/ConsultantService";
import MeetingCardByID from "../components/MeetingCardByID";
import cl from './styles/ConsMeetingCard.module.css'
import cl2 from './styles/AdminGroupCard.module.css'
import EnrolledClientsList from "../components/EnrolledClientsList";
import MyInput from "../UI/input/MyInput";
import Loader from "../UI/loader/Loader";
import MyModal from "../UI/modal/MyModal";
import NewMeeting from "../components/NewMeeting";
import {Context} from "../index";
import cfg from "../cfg";


const ConsMeetingCard = () => {
    // Общий блок определения переменных
    const params = useParams()
    const meetingID = params.id
    const now = new Date()
    const {store} = useContext(Context)
    const [backendResponse, setBackendResponse] = useState('')
    const [modalIsActive, setModalIsActive] = useState(false)
    const [entryIsActive, setEntryIsActive] = useState(false)
    const [editIsActive, setEditIsActive] = useState(false)
    const [repeatIsActive, setRepeatIsActive] = useState(false)

    // Блок получения информации о встрече
    const [meetingData, setMeetingData] = useState({})
    const [copyMeetingData, setCopyMeetingData] = useState({})
    const [fetchMeeting, meetingIsLoading, meetingError] = useFetching(
        async () => {
        const response = await ConsultantService.getMeetingByID(meetingID)
        const startDate = new Date(response.meeting_start_date)
        const endDate = new Date(response.meeting_end_date)
        let currentMonth = startDate.getMonth() + 1
        if (currentMonth < 10) {
            currentMonth = `0${currentMonth}`
        }
        const meetingParseDates = {
            ...response,
            meeting_date: `${startDate.getFullYear()}-${currentMonth}-${startDate.getDate()}`,
            start_time: `${startDate.getHours()}:${startDate.getMinutes()}`,
            end_time: `${endDate.getHours()}:${endDate.getMinutes()}`,
        }
        setMeetingData(meetingParseDates)
        setCopyMeetingData(meetingParseDates)
    })

    // Блок получения списка клиентов
    const [clients, setClients] = useState([])
    const [fetchClients, clientsIsLoading, clientsError] = useFetching(
        async () => {
            if (store.userRoleID === cfg.adminID) {
                const response = await ConsultantService.getClients(meetingData.type_of_meeting, meetingData.cons_id)
                setClients(response)
            }
            else if (store.userRoleID === cfg.consultantID) {
                const response = await ConsultantService.getClients(store.typeOfActivity, store.userID)
                setClients(response)
            }
        })

    const [enrolledClientList, setEnrolledClientList] = useState([])
    const [fetchEnrolledClients, enrolledClientsIsLoading, enrolledClientsError] = useFetching(
        async () => {
        const response = await ConsultantService.getMeetingEnrolledList(meetingID)
        setEnrolledClientList(response)
    })

    const [currentClientID, setCurrentClientID] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const filteredClients = useMemo(() => {
        return clients.filter(user => (
            `${user.name.toUpperCase()} ${user.surname.toUpperCase()}`.includes(searchQuery.toUpperCase()
            )))
    }, [searchQuery, clients])

    // Подгрузка начальных сведений при рендеринге встречи
    useEffect(() => {
        fetchMeeting()
        fetchEnrolledClients()
    }, []);

    useEffect(() => {
        if (Object.keys(meetingData).length) {
            fetchClients()
        }
    }, [meetingData]);

    // Блок для работы с записями на встречу
    const [addEntry, addEntryIsLoading, addEntryError] = useFetching(
        async () => {
            if (!currentClientID) {
                setBackendResponse('Не выбран клиент')
            }
            else {
                const response = await ConsultantService.addNewEntry({
                    meeting_name: meetingData.name,
                    meeting_id: meetingID,
                    cons_id: meetingData.cons_id,
                    cons_name: meetingData.cons_name,
                    meeting_start_date: meetingData.meeting_start_date,
                    meeting_end_date: meetingData.meeting_end_date,
                    client_id: currentClientID,
                    is_presence: false,
                })
                setBackendResponse(response)
            }
            setModalIsActive(true)
        })

    const [deleteEntry, deleteEntryIsLoading, deleteEntryError] = useFetching(
        async () => {
            if (!currentClientID) {
                setBackendResponse('Не выбран клиент')
            }
            else {
                const response = await ConsultantService.deleteMeetingEntry({
                    meeting_id: meetingID,
                    client_id: currentClientID,
                })
                setBackendResponse(response)
            }
            setModalIsActive(true)
        })

    const [confirmEntry, confirmIsLoading, confirmError] = useFetching(
        async () => {
            const response = await ConsultantService.confirmMeetingEntry({meeting_id: meetingID})
            setBackendResponse(response)
            setModalIsActive(true)
        })

    useEffect(() => {
        if (confirmError || addEntryError || deleteEntryError || clientsError || meetingError) {
            setBackendResponse(confirmError + addEntryError + deleteEntryError + clientsError + meetingError)
            setModalIsActive(true)
        }
    }, [confirmError, addEntryError, deleteEntryError, clientsError, meetingError]);

    const changeMeetingData = (label, event) => {
        switch (label) {
            case 'name':
                setCopyMeetingData({ ...copyMeetingData, name: event.target.value });
                break;
            case 'meeting_date':
                setCopyMeetingData({ ...copyMeetingData, meeting_date: event.target.value });
                break;
            case 'start_time':
                setCopyMeetingData({ ...copyMeetingData, start_time: event.target.value });
                break;
            case 'end_time':
                setCopyMeetingData({ ...copyMeetingData, end_time: event.target.value });
                break;
            case 'description':
                setCopyMeetingData({ ...copyMeetingData, description: event.target.value });
                break;
            case 'self_sign_up':
                setCopyMeetingData({ ...copyMeetingData, self_sign_up: event.target.checked });
                break;
        }
    }

    const [changeMeetingFunc, changeMeetingIsLoading, changeMeetingError] = useFetching(
        async () => {
            setModalIsActive(false)
            if (!copyMeetingData.name || !copyMeetingData.description) {
                setBackendResponse('Заполните название и описание встречи')
            }
            else if (!copyMeetingData.meeting_date || !copyMeetingData.start_time || !copyMeetingData.end_time) {
                setBackendResponse('Укажите даты начала и окончания встречи')
            }
            else {
                const response = await ConsultantService.changeMeeting({
                    ...copyMeetingData,
                    meeting_start_date: `${copyMeetingData.meeting_date}T${copyMeetingData.start_time}`,
                    meeting_end_date: `${copyMeetingData.meeting_date}T${copyMeetingData.end_time}`
                })
                setMeetingData(response)
                setBackendResponse('Встреча отредактирована. Нажмите "Закрыть"')
            }
            setModalIsActive(true)
    })

    const [deactivateMeetingFunc, deactivateMeetingIsLoading, deactivateMeetingError] =
        useFetching(
        async () => {
            setModalIsActive(false)
            if (enrolledClientList.length) {
                setBackendResponse('На встречу уже записан клиент, деактивация встречи невозможна. ' +
                    'Сначала свяжитесь с клиентом и удалите запись на встречу')
                setModalIsActive(true)
            }
            else {
                const response = await ConsultantService.changeMeetingStatus({
                    id: meetingID,
                    is_active: false})
                setBackendResponse(response)
                setModalIsActive(true)
                setTimeout(() => {window.location.reload()}, [1000])
            }
        })

    const [activateMeetingFunc, activateMeetingIsLoading, activateMeetingError] =
        useFetching(
        async () => {
            setModalIsActive(false)
            const response = await ConsultantService.changeMeetingStatus({id: meetingID, is_active: true})
            setBackendResponse(response)
            setModalIsActive(true)
            setTimeout(() => {window.location.reload()}, [1000])
            })

    const [fetchNewMeeting, newMeetingIsLoading, newMeetingError] = useFetching(
        async () => {
            setModalIsActive(false)
            if (!copyMeetingData.name || !copyMeetingData.description) {
                setBackendResponse('Заполните название и описание встречи')
            }
            else if (!copyMeetingData.meeting_date || !copyMeetingData.start_time || !copyMeetingData.end_time) {
                setBackendResponse('Укажите дату и время начала и окончания встречи')
            }
            else {
                const response = await ConsultantService.addNewMeeting({
                    ...copyMeetingData,
                    meeting_start_date: `${copyMeetingData.meeting_date}T${copyMeetingData.start_time}`,
                    meeting_end_date: `${copyMeetingData.meeting_date}T${copyMeetingData.end_time}`
                })
                setBackendResponse(response)
            }
            setModalIsActive(true)
        })

    return (
        <div className={cl.content}>
            <div className={cl.meeting_buttons}>
                <button
                    onClick={() => {
                        setModalIsActive(false)
                        setEntryIsActive(!entryIsActive)
                        setEditIsActive(false)
                        setRepeatIsActive(false)
                    }}
                >Записать клиента
                </button>
                <button
                    onClick={() => {
                        setModalIsActive(false)
                        if (new Date(meetingData.meeting_end_date) > now) {
                            setBackendResponse(
                                'Подтвердить присутствие можно только позднее даты окончания встречи')
                            setModalIsActive(true)
                        } else {
                            setEntryIsActive(false)
                            setEditIsActive(false)
                            setRepeatIsActive(false)
                            confirmEntry()
                        }
                    }}
                >Подтвердить присутствие
                </button>
                <button
                    onClick={() => {
                        setModalIsActive(false)
                        setEditIsActive(!editIsActive)
                        setEntryIsActive(false)
                        setRepeatIsActive(false)
                    }}
                >Редактировать
                </button>
                <button
                    onClick={() => {
                        setModalIsActive(false)
                        setRepeatIsActive(!repeatIsActive)
                        setEntryIsActive(false)
                        setEditIsActive(false)
                    }}
                >Повторить
                </button>
                <button
                    className={meetingData.is_active ? cl.plug : cl.invisible}
                    onClick={() => {
                        setModalIsActive(false)
                        setRepeatIsActive(false)
                        setEntryIsActive(false)
                        setEditIsActive(false)
                        deactivateMeetingFunc()
                    }}
                >Удалить
                </button>
                <button
                    className={!meetingData.is_active ? cl.plug : cl.invisible}
                    onClick={() => {
                        setModalIsActive(false)
                        setRepeatIsActive(false)
                        setEntryIsActive(false)
                        setEditIsActive(false)
                        activateMeetingFunc()
                    }}
                >Активировать
                </button>
            </div>
            {meetingIsLoading || changeMeetingIsLoading
                ? <div className={cl2.loader}><Loader/></div>
                : <div>
                    <MeetingCardByID
                        meeting={meetingData}
                    />
                </div>
            }

            <div className={cl.modal}>
                <MyModal
                    active={modalIsActive}
                    setActive={setModalIsActive}
                    children={backendResponse}
                />
            </div>
            <div>
                <EnrolledClientsList
                    clients={enrolledClientList}
                    clientsIsLoading={enrolledClientsIsLoading}
                />
            </div>
            <div className={entryIsActive ? cl.plug : cl.invisible}>
                <MyInput
                    placeholder='Поиск клиентов...'
                    value={searchQuery}
                    onChange={(e) => {
                        setSearchQuery(e.target.value)
                    }}/>
                <div className={cl.meeting_buttons}>
                    <button
                        onClick={() => {
                            addEntry()
                        }}
                    >Записать
                    </button>
                    <button
                        onClick={() => {
                            deleteEntry()
                        }}
                    >Удалить запись
                    </button>
                    <button
                        onClick={() => {
                            window.location.reload()
                        }}
                    >Закрыть
                    </button>
                </div>

                {clientsIsLoading
                    ? <div className={cl2.loader}><Loader/></div>
                    : <div className={cl.clients}>
                        {filteredClients.length
                            ? filteredClients.map((row) => (
                                <div
                                    key={row.id}
                                    className={cl2.checkbox_client_list}
                                >
                                    <input
                                        type='radio'
                                        id={row.id}
                                        name='individual'
                                        onClick={(event) => {
                                            setCurrentClientID(event.target.id)
                                        }}
                                    />
                                    <p>{row.surname} {row.name}</p>
                                </div>
                            ))
                            : <h4>Клиенты не найдены</h4>
                        }
                    </div>
                }
            </div>
            <div className={editIsActive ? cl.edit_meeting : cl.invisible}>
                <h3>Редактировать встречу</h3>
                <NewMeeting
                    isActive={editIsActive}
                    changeNewMeetingData={changeMeetingData}
                    createFunction={changeMeetingFunc}
                    buttonName='Ok'
                    meetingData={meetingData}
                />
            </div>
            <div className={repeatIsActive ? cl.edit_meeting : cl.invisible}>
                <h3>Создание новой встречи на основе текущей</h3>
                <NewMeeting
                    isActive={repeatIsActive}
                    changeNewMeetingData={changeMeetingData}
                    createFunction={fetchNewMeeting}
                    buttonName='Создать'
                    meetingData={copyMeetingData}
                    repeat={true}
                />
            </div>
        </div>
    );
};

export default ConsMeetingCard;