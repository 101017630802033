import React, {useEffect, useMemo, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import AdminService from "../services/AdminService";
import Loader from "../UI/loader/Loader";
import cl from "../pages/styles/AdminGroupDirections.module.css";
import MyModal from "../UI/modal/MyModal";

const AdminGroupDirsList = ({directionsListIsActive}) => {

    const [modalIsActive, setModalIsActive] = useState(false)
    const [backendResponse, setBackendResponse] = useState('')
    const [showDeactivationDirs, setShowDeactivationDirs] = useState(false)

    const [listDirections, setListDirections] = useState([])

    const [fetchDirections, fetchDirectionsIsLoading, fetchDirectionsError] = useFetching(
        async () => {
            const response = await AdminService.getAllGroupDirections()
            setListDirections(response)
        })

    const filteredDirs = useMemo(() => {
        if (showDeactivationDirs) {
            return listDirections
        }
        else {
            return listDirections.filter(dir => (
                dir.is_active === true
            ))
        }
    }, [showDeactivationDirs, listDirections, directionsListIsActive]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fetchDirectionsError.length) {
            setBackendResponse(fetchDirectionsError)
            setModalIsActive(true)
        }
    }, [fetchDirectionsError]);

    useEffect(() => {
        fetchDirections()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={directionsListIsActive ? cl.direction_content : cl.invisible}>
            <h3>Список направлений</h3>
            <div className={cl.checkbox}>
                <input
                    type="checkbox"
                    onChange={(event) => {
                        setShowDeactivationDirs(event.target.checked)
                    }}
                />
                <p>Показать деактивированные направления</p>
            </div>
            {fetchDirectionsIsLoading
                ? <div><Loader/></div>
                : filteredDirs.map((direction) => (
                    <div
                        key={direction.id}
                        className={cl.dirs_list}>
                        <a href={`/admin/group_direction/${direction.id}`}>
                            {direction.is_active ? direction.name : <s>{direction.name}</s>}
                        </a>
                    </div>
                ))
            }
            {modalIsActive
                ? <MyModal
                    active={modalIsActive}
                    setActive={setModalIsActive}
                    children={backendResponse}
                />
                : void {}

            }
        </div>
    );
};

export default AdminGroupDirsList;