import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from './navbar/Navbar';
import {useContext, useEffect} from "react";
import {Context} from "./index";
import {observer} from "mobx-react-lite";
import Config from './cfg'
import {adminRoutes, authRoutes, clientRoutes, consRoutes, unAuthRoutes, unVerifiedClientRoutes} from "./routes";

function App() {

  const {store} = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      store.checkAuth()
    }
  }, []);

  if (store.userRoleID === Config.adminID) {
    return (
        <BrowserRouter>
          <Navbar/>
          <Routes>
              {authRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
              {clientRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
              {consRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
              {adminRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
          </Routes>
        </BrowserRouter>
    )
  }

  else if (store.userRoleID === Config.consultantID) {
    return (
        <BrowserRouter>
          <Navbar/>
          <Routes>
              {authRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
              {clientRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
              {consRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}

          </Routes>
        </BrowserRouter>
    )
  }

  else if (store.userRoleID === Config.clientID && store.isVerified) {
    return (
        <BrowserRouter>
          <Navbar/>
          <Routes>
              {authRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
              {clientRoutes.map(route => (
                  <Route key={route.component} path={route.path} element={<route.component/>}/>
              ))}
          </Routes>
        </BrowserRouter>
    )
  }

  else if (store.userRoleID === Config.clientID && !store.isVerified) {
      return (
            <BrowserRouter>
                <Navbar/>
                <Routes>
                    {authRoutes.map(route => (
                        <Route key={route.component} path={route.path} element={<route.component/>}/>
                    ))}
                    {unVerifiedClientRoutes.map(route => (
                        <Route key={route.component} path={route.path} element={<route.component/>}/>
                    ))}
                </Routes>
            </BrowserRouter>
        )
    }

  else {
      return (
          <BrowserRouter>
              <Navbar/>
              <Routes>
                  {unAuthRoutes.map(route => (
                      <Route key={route.component} path={route.path} element={<route.component/>}/>
                  ))}
              </Routes>
          </BrowserRouter>
      )
  }
}

export default observer(App);